import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from 'next/config'
import { getCookiesValue } from 'helpers/cookie'

export const {
  publicRuntimeConfig: { baseURL },
} = getConfig()

export const api = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 60 * 60,
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const accessToken = getCookiesValue('ac_token')
      const id = window?.Echo?.socketId?.()
      headers.set('Authorization', `Bearer ${accessToken}`)
      headers.set('Content-Type', 'application/json')
      headers.set('accept', 'application/json')

      if (id) {
        headers.set('X-Socket-Id', id)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})
