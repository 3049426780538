import SimpleBar from 'simplebar-react';

const Scrollbar = ({ children, maxHeight, ...props }) => {
  return (
    <SimpleBar {...props} style={{maxHeight}} >
      {children}
    </SimpleBar>
  )
}

export default Scrollbar