import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRoleList } from 'api/roles/';

export const getRoleListAction = createAsyncThunk(
  'roles/getList',
  async (data) => {
    return await getRoleList(data);
  }
);

const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    getListStatus: 'idle',
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleListAction.pending, (state) => {
        state.getListStatus = 'pending';
      })
      .addCase(getRoleListAction.fulfilled, (state, action) => {
        state.list = action.payload.data || [];
        state.getListStatus = 'idle';
      });
  },
});

export const roleListSelector = (state) => state.roles.list;
export const roleStatusSelector = (state) => state.roles.getListStatus;

export default rolesSlice.reducer;
