import { useEffect, useRef } from 'react'
import { path } from 'utils/constants/path'
import { Avatar } from './styled'

export const AvatarSpecial = ({ src, size = '', alt = '' }) => {
  const imgRef = useRef(null)
  const { defaultAvatarPath } = path

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.setAttribute('referrerpolicy', 'no-referrer')
      imgRef.current.src = src
    }
  }, [imgRef])

  return (
    <Avatar
      ref={imgRef}
      src={src}
      alt={alt}
      size={size}
      onError={() => {
        imgRef.current.src = defaultAvatarPath
      }}
    />
  )
}
