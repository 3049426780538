import styled from 'styled-components'

export const TagListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  grid-gap: 15px;
`
export const NewTagWrapper = styled.form`
  display: flex;
  align-items: top;
  grid-gap: 15px;
`
export const ButtonCell = styled.div`
  margin-top: 23px;
  width: 50%;
`
export const InputWrap = styled.div`
  width: 50%;
`
