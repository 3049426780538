import { api } from '../api'

export const gendersApi = api
  .enhanceEndpoints({
    addTagTypes: ['genders'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGradesList: builder.query({
        providesTags: ['projectType'],
        query: () => {
          return {
            url: `/grade-directions`,
            method: 'get',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const { useGetGradesListQuery } = gendersApi
