export default (url) => {
  if(!url) return true;

  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};
