const Black = {
  TextBackground: '#1B1A1D',
  BlocksBackground: '#222222',
  ButtonsSidebar: '#2D2D2D',
  IconsSidebar: '#787878',
  TextSec: '#A6A6A6',
  BlueGrey: '#ABBED1',
}

const Blue = {
  DarkBlue: '#0053AD',
  SkyBlue: '#DBEDFF',
  BackBlue: '#EEF5FC',
  Tags: '#4761EE',
}

const DS = {
  MainBlue: '#3787EB',
  MainPink: '#EA5471',
}

const Green = {
  DarDarkMGreenk: '#009262',
  GreenHerbal: '#32C997',
  MintGreen: '#84DFC1',
  BackGreen: '#F1FBF8',
}

const Light = {
  White: '#ffffff',
  CardsBack: '#F4F6F8',
  LinesGrey: '#EEEEEE',
  VisibleThertiary: '#D2D2D2',
}

const Purple = {
  MPurple: '#6457FA',
  Lavand: '#A29AFC',
  LightPurple: '#D1CDFE',
  BackPurple: '#F0EEFF',
  Tags: '#C554F5',
}

const Yellow = {
  DYellow: '#E48900',
  Lemon: '#FFA826',
  MYellow: '#FFB240',
  Straw: '#FFC670',
  Sand: '#FFD596',
  LYellow: '#FFF8EC',
}

const Red = {
  DRed: '#C43025',
  Scarlet: '#E01507',
  MRed: '#E02B1D',
  Raspberry: '#FF5A4F',
  Rubicund: '#F0857D',
  LRed: '#FFF1F0',
}

const Orange = {
  OrangeMain: '#FF6E41',
  BackOrange: '#FFF1EC',
}

const Lagoon = {
  Lagoon: '#18BACE',
  LightBack: '#E9FAFC',
}

export const Colors = {
  DS,
  Black,
  Purple,
  Blue,
  Green,
  Yellow,
  Red,
  Orange,
  Lagoon,
  Light,
}
