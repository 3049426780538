import styled from 'styled-components'
import { CheckboxWrap } from 'ui/components/forms/Checkbox/styled.js'
import { breakpoints } from 'utils/constants'

export const Section = styled.div`
  position: relative;
  margin-top: 21px;

  @media ${breakpoints.mobileMax} {
    padding-bottom: 30px;
    margin-top: 16px;
  }
`

export const SectionForm = styled.div`
  margin-bottom: 20px;

  ${CheckboxWrap} {
    margin-bottom: 43px;

    @media ${breakpoints.mobileMax} {
      margin-top: 16px;
    }
  }

  @media ${breakpoints.mobileMax} {
    margin-bottom: 16px;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  justify-content: space-between;
`
