import makeRequest from 'api/'

export const fetchGradesList = async () => {
  return await makeRequest({
    method: 'get',
    url: `/grade-directions`,
  })
}

export const fetchUsersGrade = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/user-grade/${id}`,
  })
}

export const updateGradeDirection = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/grade-directions/${id}`,
    data,
  })
}

export const updateGrade = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/grades/${id}`,
    data,
  })
}

export const updateGradeItem = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/grade-items/${id}`,
    data,
  })
}

export const createGradeDirection = async (data) => {
  return await makeRequest({
    method: 'post',
    url: `/grade-directions`,
    data,
  })
}

export const createGrade = async (data) => {
  return await makeRequest({
    method: 'post',
    url: `/grades`,
    data,
  })
}

export const createGradeItem = async (data) => {
  return await makeRequest({
    method: 'post',
    url: `/grade-items`,
    data,
  })
}

export const deleteGradeDirection = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/grade-directions/${id}`,
  })
}

export const deleteGrade = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/grades/${id}`,
  })
}

export const deleteGradeItem = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/grade-items/${id}`,
  })
}
