import makeRequest from 'api/'

export const getProjectType = async () => {
  return await makeRequest({
    method: 'get',
    url: `/project_type`,
  })
}

export const getComponent = async () => {
  return await makeRequest({
    method: 'get',
    url: `/component`,
  })
}

export const getPriority = async () => {
  return await makeRequest({
    method: 'get',
    url: `/priority`,
  })
}

export const getTaskType = async () => {
  return await makeRequest({
    method: 'get',
    url: `/task_type `,
  })
}

export const getTaskStage = async () => {
  return await makeRequest({
    method: 'get',
    url: '/stage',
  })
}

export const getFlow = async () => {
  return await makeRequest({
    method: 'get',
    url: `/flow `,
  })
}

export const getGenders = async () => {
  return await makeRequest({
    method: 'get',
    url: '/gender',
  })
}

export const getTags = async () => {
  return await makeRequest({
    method: 'get',
    url: '/tag',
  })
}

export const getEpics = async (data) => {
  const query = data.map((id, index) => `projects[${index}]=${id}`).join('&')

  return await makeRequest({
    method: 'get',
    url: `dictionary/epics?${query}`,
  })
}

export const createTag = async (data) => {
  return makeRequest({
    method: 'post',
    url: '/tag',
    data,
  })
}

export const deleteTag = async (id) => {
  return makeRequest({
    method: 'delete',
    url: `/tag/${id}`,
  })
}
