import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getReports } from 'api/reports'

export const getReportsAction = createAsyncThunk(
  'reports/getReports',
  async (data) => {
    return await getReports(data.type, data.filter)
  }
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    getReportsStatus: 'idle',
    reportType: 'idle',
    reports: [],
  },
  reducers: {
    clearReportsStatus: (state) => {
      state.getReportsStatus = 'idle'
    },
    clearReportsData: (state) => {
      state.reports = []
      state.reportType = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsAction.pending, (state) => {
        state.getReportsStatus = 'pending'
      })
      .addCase(getReportsAction.fulfilled, (state, action) => {
        state.reports = action.payload.report.data || null
        state.getReportsStatus = 'success'
        state.reportType = action.payload.type
      })
  },
})

export const getReportsStatusSelector = (state) =>
  state.reports.getReportsStatus
export const reportsSelector = (state) => state.reports.reports
export const reportTypeSelector = (state) => state.reports.reportType
export const { clearReportsStatus } = reportsSlice.actions
export const { clearReportsData } = reportsSlice.actions

export default reportsSlice.reducer
