import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { useState, useCallback, useEffect } from 'react'
import { PortalWithState as Portal } from 'react-portal'

const SidebarModalOverlay = dynamic(
  () => import('./styled').then((module) => module.SidebarModalOverlay),
  { ssr: false }
)

const SidebarModalBody = dynamic(
  () => import('./styled').then((module) => module.SidebarModalBody),
  { ssr: false }
)

const SidebarModal = ({
  children,
  isOpened = false,
  onCloseHandler,
  trigger,
  triggerStyle,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [down, setDown] = useState(false)
  const [up, setUp] = useState(false)

  const openModal = useCallback(() => {
    setIsOpenModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    if (typeof onCloseHandler === 'function') {
      onCloseHandler()
    }
  }, [])

  useEffect(() => {
    setIsOpenModal(isOpened)
  }, [isOpened])

  const handler = (event, setState) => {
    const currentTarget = event.currentTarget
    const target = event.target

    if (target.contains(currentTarget)) {
      setState(true)
    }
  }

  const handleMouseDown = (event) => handler(event, setDown)
  const handleMouseUp = (event) => handler(event, setUp)

  useEffect(() => {
    if (up && down) {
      closeModal()
      setUp(false)
      setDown(false)
    }
  }, [up, down])

  return (
    <Portal defaultOpen>
      {({ isOpen, portal }) => {
        const childrenWithProps = React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { openModal, closeModal, isOpen })
          }
          return child
        })

        return (
          <>
            <div onClick={openModal} style={triggerStyle}>
              {trigger}
            </div>
            {portal(
              <SidebarModalOverlay
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                className={classNames({ isOpen: isOpenModal })}
              >
                <SidebarModalBody
                  className={classNames({ isOpen: isOpenModal })}
                >
                  {childrenWithProps}
                </SidebarModalBody>
              </SidebarModalOverlay>
            )}
          </>
        )
      }}
    </Portal>
  )
}

export default SidebarModal
