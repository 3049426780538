import styled, { css } from 'styled-components'
import { PALETTE } from 'styles/constants'
import { Colors, Transition } from 'utils/constants'

export const ButtonStyled = styled.button`
  width: 100%;
  height: 40px;
  cursor: pointer;
  position: relative;
  padding: 0 24px;
  background: transparent;
  border-radius: 6px;
  transition: ${Transition.ms300};

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth};
    `}

  ${(p) =>
    p.theme === 'base' &&
    css`
      color: ${Colors.Light.White};
      background-color: ${Colors.DS.MainBlue};

      &:hover {
        background-color: ${PALETTE.lightPrimaryMiddle};
      }
    `}

  ${(p) =>
    p.theme === 'dashedGrey' &&
    css`
      color: ${PALETTE.darkBlue};
      border: 1px dashed ${PALETTE.lightGrey};

      &:hover {
        border-color: ${PALETTE.midGrey};
      }
    `}

  ${(p) =>
    p.theme === 'borderGrey' &&
    css`
      color: ${PALETTE.darkBlue};
      border: 1px solid ${PALETTE.lightGrey};

      &:hover {
        border-color: ${PALETTE.midGrey};
      }
    `}

    ${(p) =>
    p.theme === 'borderRed' &&
    css`
      color: ${PALETTE.popPink};
      border: 1px solid ${PALETTE.popPink};

      &:hover {
        border-color: ${PALETTE.popPink};
      }
    `}

    ${(p) =>
    p.theme === 'borderBlue' &&
    css`
      color: ${PALETTE.primary};
      border: 1px solid ${PALETTE.primary};

      &:hover {
        color: ${PALETTE.lightPrimary};
        border-color: ${PALETTE.lightPrimary};
      }
    `}

  ${(p) =>
    p.loading === 'true' &&
    css`
      pointer-events: none;

      span,
      svg {
        opacity: 0;
      }
    `}

  svg {
    position: relative;
    top: -1px;
    vertical-align: middle;
    margin-right: 9px;
  }
`

export const ButtonTitle = styled.span`
  font-size: 14px;
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export const ButtonLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/loading-white.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center center;
`
