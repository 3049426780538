import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectTasks } from 'api/tasksList';

export const getProjectTasksAction = createAsyncThunk(
  'tasksList/getProjectTasks',
  async (data) => {
    return await getProjectTasks(data.slug, data, data.controller);
  }
);

const tasksListSlice = createSlice({
  name: 'tasksList',
  initialState: {
    getProjectTaskStatus: 'idle',
    tasks: [],
    meta: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectTasksAction.pending, (state) => {
        state.getProjectTaskStatus = 'pending';
      })
      .addCase(getProjectTasksAction.fulfilled, (state, action) => {
        state.getProjectTaskStatus = 'success';
        state.tasks = action.payload.data || [];
        state.meta = action.payload.meta || null;
      })
      .addCase(getProjectTasksAction.rejected, (state) => {
        state.getProjectTaskStatus = 'error';
      })
  }
});

export const tasksListSelector = (state) => state.tasksList.tasks;
export const tasksListMetaSelector = (state) => state.tasksList.meta;

export default tasksListSlice.reducer;