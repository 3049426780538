import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const createCookies = (name, value, options) => {
  cookies.set(name, value, options)
}

export const checkExistenceCookies = (name) => {
  return cookies.get(name) ? true : false
}

export const getCookiesValue = (name) => {
  return cookies.get(name)
}

export const removeCookie = (name) => {
  cookies.remove(name, { path: '/' })
}
