import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { TRANSFORMERS } from '@lexical/markdown'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
// import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import classNames from 'classnames'
import {
  InputWrap,
  InputLabel,
  InputError,
} from 'ui/components/forms/Input/styled'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import LoadInitialContent from './plugins/LoadInitialContent'
import OnChangePlugin from './plugins/OnChangePlugin'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import { EditorWrap } from './styled'
import baseTheme from './themes/BaseTheme'

const editorConfig = {
  theme: baseTheme,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
  onError(error) {
    throw error
  },
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text)
    if (match === null) {
      return null
    }
    const fullMatch = match[0]
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      attributes: { rel: 'noreferrer', target: '_blank' },
    }
  },
]

export const Editor = ({
  meta,
  label,
  required,
  value,
  onChange,
  placeholder = 'Введите текст',
}) => {
  const isTouched = meta && meta.touched
  const hasError = isTouched && meta.error

  return (
    <InputWrap
      as={'div'}
      className={classNames({
        'has-error': hasError,
      })}
    >
      {label && (
        <InputLabel>
          {label} {required && <span>*</span>}
        </InputLabel>
      )}
      <EditorWrap>
        <LexicalComposer initialConfig={editorConfig}>
          <div className="editor-container">
            <ToolbarPlugin />
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={
                  <div className="editor-placeholder">{placeholder}</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ListPlugin />
              {/* <LinkPlugin /> */}
              <AutoLinkPlugin matchers={MATCHERS} />
              <CodeHighlightPlugin />
              <LoadInitialContent initValue={value} />
              <OnChangePlugin onChange={onChange} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            </div>
          </div>
        </LexicalComposer>
      </EditorWrap>
      {hasError && <InputError>{meta.error}</InputError>}
    </InputWrap>
  )
}
