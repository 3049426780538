import { api } from '../api'

export const usersApi = api
  .enhanceEndpoints({
    addTagTypes: ['userList', 'currentUser'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserList: builder.query({
        providesTags: ['userList'],
        query: (statuses) => {
          let params = statuses
            ? statuses
                .map((status, index) => `status[${index}]=${status}`)
                .join('&')
            : null
          return {
            url: params ? `/user?${params}` : '/user',
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      getCurrentUser: builder.query({
        providesTags: ['currentUser'],
        query: () => {
          return {
            url: '/auth/user',
            method: 'get',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      createUser: builder.mutation({
        query: (data) => {
          return {
            url: '/user',
            method: 'post',
            body: data,
          }
        },
        transformResponse: (response) => response?.data || [],
        invalidatesTags: ['userList'],
      }),
      updateUserTags: builder.mutation({
        query: (data) => {
          return {
            method: 'PATCH',
            url: `/user/${data.userId}/tags`,
            body: {
              tags: data.tags,
            },
          }
        },
        transformResponse: (response) => response?.data || [],
        invalidatesTags: ['userList', 'currentUser'],
      }),
      getUserById: builder.query({
        providesTags: ['userById'],
        query: (id) => {
          return {
            method: 'GET',
            url: `/user/${id}`,
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      updateUser: builder.mutation({
        query: (data) => {
          return {
            method: 'PATCH',
            url: `/user/${data.id}`,
            body: data.values,
          }
        },
        transformResponse: (response) => response?.data || [],
        invalidatesTags: ['userList', 'currentUser', 'userById'],
      }),
      getTimeUser: builder.query({
        query: () => {
          return {
            method: 'GET',
            url: `/user/time/me`,
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const {
  useGetUserListQuery,
  useGetCurrentUserQuery,
  useCreateUserMutation,
  useUpdateUserTagsMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useGetTimeUserQuery,
} = usersApi
