import styled from 'styled-components'

export const Wrapper = styled.div``

export const UserList = styled.div`
  margin-bottom: 20px;
`

export const UserElem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const UserCard = styled.div`
  width: calc(100% - 30px);
`

export const UserDelete = styled.div`
  width: 30px;
`

export const ControlIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
