import styled, { css } from 'styled-components'
import { Colors, Transition } from 'utils/constants'

export const Wrapper = styled.div`
  padding: 24px 16px;
`
export const Elem = styled.a`
  height: 41px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 36px;
  user-select: none;
  transition: ${Transition.ms300} background-color;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &:hover {
    background-color: ${Colors.Black.ButtonsSidebar};
  }

  &[aria-selected='true'] {
    background-color: ${Colors.Black.ButtonsSidebar};
    &:hover {
      background-color: ${Colors.Black.ButtonsSidebar};
    }
  }

  ${(p) =>
    p.theme === 'light' &&
    css`
      color: ${Colors.Black.ButtonsSidebar};
      background-color: transparent;
      &:hover {
        color: ${Colors.Black.BlocksBackground};
        background-color: ${Colors.Light.White};
      }
      &[aria-selected='true'] {
        background-color: ${Colors.Light.White};
        color: ${Colors.Black.BlocksBackground};
        &:hover {
          background-color: ${Colors.Light.White};
          color: ${Colors.Black.BlocksBackground};
        }
      }
    `}
`

export const ProjectMenu = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${Colors.Black.ButtonsSidebar};

  ${Elem} {
    &[aria-selected='true'] {
      background-color: ${Colors.Black.ButtonsSidebar};
      &:hover {
        background-color: ${Colors.Black.ButtonsSidebar};
      }
    }
    ${(p) =>
      p.theme === 'light' &&
      css`
        color: ${Colors.Black.ButtonsSidebar};
        background-color: transparent;
        &:hover {
          color: ${Colors.Black.BlocksBackground};
          background-color: ${Colors.Light.CardsBack};
        }
        &[aria-selected='true'] {
          color: ${Colors.Black.BlocksBackground};
          background-color: ${Colors.Light.CardsBack};
          &:hover {
            color: ${Colors.Black.BlocksBackground};
            background-color: ${Colors.Light.CardsBack};
          }
        }
      `}
  }
`

export const ProjectMenuBox = styled.div`
  padding: 8px;
  background: ${Colors.Black.BlocksBackground};
  border-radius: 8px;
`

export const ProjectMenuTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const ProjectLogo = styled.div`
  width: 32px;
  height: 32px;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
`

export const ProjectName = styled.div`
  width: calc(100% - 32px);
  font-size: 14px;
  font-weight: 500;
  padding-left: 8px;
`

export const ProjectMenuList = styled.div`
  position: relative;
`

export const List = styled.div``

export const ControlIcon = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto;
  color: ${Colors.Black.IconsSidebar};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  svg {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
`

export const ControlTitle = styled.div`
  font-weight: 500;
`
