import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'

export const SelectRow = styled.div`
  display: flex;
  align-items: end;
  gap: 0 10px;
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

export const SelectWrapper = styled.div`
  margin-bottom: 0;
  width: 100%;
`

export const AddButtonWrap = styled.div`
  margin-bottom: 20px;
`
export const DeleteButtonWrap = styled.div`
  margin-bottom: 15px;
`

export const SelectCol = styled.div`
  width: calc(50% - 40px);

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    margin: 5px 0;
  }
`
