export const ValidationPhrase = {
  required: 'Нужно заполнить',
  tel: 'Пожалуйста, введите корректный телефон',
  minLength: 'Длина не может быть меньше',
  onlyNumbers: 'Введите только цифры',
  onlyLetters: 'Введите только буквы или цифры без пробелов',
  checkPersData:
    'Пожалуйста, подтвердите согласие на обработку персональных данных',
  email: 'Введите корректный почтовый адрес',
  agree:
    'Пожалуйста, подтвердите согласие на обработку персональных данных',
  policy:
    'Пожалуйста, подтвердите согласие c политикой конфиденциальности и условиями сайта',
  dateMax: 'Дата не может быть позже текущего дня',
  fileSize20: 'Размер файла не может превышать 20MB',
  fileSize5: 'Размер файла не может превышать 5MB',
}
