import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const File = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  padding: 5px 7px;
  margin: 0 5px 5px 0;
  background-color: ${PALETTE.lightGrey};
  border-radius: 4px;
`;

export const FileTitle = styled.div`
  padding-bottom: 2px;

  &:not(:last-child) {
    margin-right: 7px;
  }
`;

export const FileDelete = styled.div`
  cursor: pointer;
  color: ${PALETTE.popPink};

  svg {
    display: block;
  }
`;
