export default (data, paramKeys = []) => {
  const formed = []

  for (let [key, value] of Object.entries(data)) {
    if (paramKeys.some((paramKey) => paramKey === key)) {
      let valueArray = value

      if (typeof value === 'string') {
        valueArray = [value]
      }

      valueArray.forEach((id) => {
        formed.push(
          Object.values({
            0: [`filter[${key}][${id}]`],
            1: String(id),
          })
        )
      })
    }
  }

  return Object.fromEntries(formed)
}
