import { api } from '../api'

export const rolesApi = api
  .enhanceEndpoints({
    addTagTypes: ['roleList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRoleList: builder.query({
        providesTags: ['roleList'],
        query: () => {
          return {
            url: `/role`,
            method: 'GET',
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const { useGetRoleListQuery } = rolesApi
