import makeRequest from 'api/'
import { notifyError } from 'ui/components/Notify'

export const uploadFile = async (data) => {
  const res = await makeRequest({
    method: 'post',
    url: '/file',
    data,
  })
  if (res.status === 413) {
    notifyError('Файл слишком большой')
    return null
  } else return res
}
