export const regLink = new RegExp(/(https?:\/\/\S+?)(\s+|<|$)/g)
export const textileRegLink = new RegExp(
  /("[^"]+":)?(https?:\/\/[^\s<,]+?)([ <>.,"'?!;:()[\]%{}]*(\s|$))|(<pre>(.*?)<\/pre>)/gms
)
export const regNewLineSymbol = new RegExp(/\n/g)
export const newLineSymbol = '\n'
export const regSubListLineSymbol = new RegExp(/^[#*]*\s.+/)
export const regSubListSymbol = new RegExp(/^[#*]+/g)
export const regLastSymbolIsSpace = new RegExp(/\s+$/g)
export const textInsidePreTagsReg = new RegExp(/<pre>(.*?)<\/pre>/gms)
export const replaceStringWithNlInsideQuotes = new RegExp(
  /(").*?[\r\n|\n].*?("). (.*?(?=http))/g
)
