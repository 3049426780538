import { useDispatch } from 'react-redux'
import { deleteTagAction } from 'store/dictionaries'
import Button from 'ui/components/buttons/Button'
import { ButtonCell, TagItem, TagName } from './styled'

const Tag = ({ data }) => {
  const dispatch = useDispatch()

  const deleteHandler = () => {
    dispatch(deleteTagAction(data.id))
  }
  return (
    <TagItem>
      <TagName color={data.color_fg} background={data.color_bg}>
        {data.tag}
      </TagName>
      <ButtonCell>
        <Button onClick={deleteHandler} theme="borderRed" title="Удалить" />
      </ButtonCell>
    </TagItem>
  )
}

export const TagElement = ({ data, width }) => {
  return (
    <TagName width={width} color={data.color_fg} background={data.color_bg}>
      {data.tag}
    </TagName>
  )
}
export default Tag
