import styled, { css } from 'styled-components'
import { PALETTE } from 'styles/constants'
import { Colors, breakpoints, Transition } from 'utils/constants'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 60px;

  @media ${breakpoints.mobileMax} {
    padding: 0;
  }
`

export const Box = styled.div`
  max-width: 100%;
  width: 384px;
  margin: auto;
  padding: 20px;
  background-color: ${Colors.Light.White};
  border-radius: 8px;

  @media ${breakpoints.mobileMax} {
    border-radius: 0;
  }
  ${(p) =>
    p.size === 'medium' &&
    css`
      width: 680px;
    `}
  ${(p) =>
    p.size === 'large' &&
    css`
      width: 60vw;
    `}
  ${(p) =>
    p.size === 'max' &&
    css`
      width: 70vw;
    `}

    ${(p) =>
    p.size === 'fullscreen' &&
    css`
      width: 100%;
    `}
`

export const CloseButton = styled.button`
  outline: none;
  color: ${PALETTE.white};
  cursor: pointer;
  position: fixed;
  height: 24px;
  top: 24px;
  right: 24px;
  padding: 7px;
  background: rgba(244, 246, 248, 0.2);
  border-radius: 6px;
  transition: ${Transition.ms300};

  svg {
    display: block;
  }

  &:hover {
    color: ${PALETTE.darkBlue};
  }

  &:active {
    opacity: 0.3;
  }
`

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.Black.BlocksBackground};
  margin-bottom: 25px;
`
