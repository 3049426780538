import { api } from '../api'

export const userGradeApi = api
  .enhanceEndpoints({
    addTagTypes: ['UserGradeTasks'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserGradeTasks: builder.query({
        query: () => {
          return {
            url: `/user-grade/tasks/list`,
          }
        },
        transformResponse: (response) => {
          if (response?.data) {
            return response?.data?.filter((item) => item?.tasks?.length > 0)
          }
          return []
        },
      }),
      getUserGradeTasksByUserId: builder.query({
        query: (userId) => {
          return {
            url: `/user-grade/tasks/${userId}`,
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const { useGetUserGradeTasksQuery, useGetUserGradeTasksByUserIdQuery } = userGradeApi
