import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectTasks } from 'api/backlogList';

export const getProjectTasksAction = createAsyncThunk(
  'backlogList/getProjectTasks',
  async (data) => {
    if (typeof data === 'string') {
      return await getProjectTasks(data, data, data.controller);
    } else {
      return await getProjectTasks(data.slug, data, data.controller);
    }
  }
);

const backlogListSlice = createSlice({
  name: 'backlogList',
  initialState: {
    getProjectTaskStatus: 'idle',
    tasks: [],
    meta: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectTasksAction.pending, (state) => {
        state.getProjectTaskStatus = 'pending';
      })
      .addCase(getProjectTasksAction.fulfilled, (state, action) => {
        state.getProjectTaskStatus = 'success';
        state.tasks = action.payload.data || [];
        state.meta = action.payload.meta || null;
      })
      .addCase(getProjectTasksAction.rejected, (state) => {
        state.getProjectTaskStatus = 'error';
      })
  }
});

export const backlogListSelector = (state) => state.backlogList.tasks;
export const backlogListMetaSelector = (state) => state.backlogList.meta;

export default backlogListSlice.reducer;