import axios from 'api/axios'
import { notifyError } from 'ui/components/Notify'

const makeRequest = async (config = {}) => {
  try {
    return await axios(config)
  } catch (error) {
    const code =
      error?.code ||
      error?.status ||
      (error?.response && error?.response.status) ||
      500
    if (code === 500 && error.message !== 'canceled') {
      notifyError('500. Ошибка на сервере.')
    }

    return {
      code: error?.code,
      status: error?.status,
      errorMessage: `${code}: ${error?.message}`,
      ...error?.response,
    }
  }
}

export default makeRequest
