export const formatToTime = (value) => {
  if (value == null || value <= 0) {
    return '0';
  }

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  const hour = hours >= 1 ? hours + 'ч' : '';
  const min = minutes > 0 ? minutes + 'м' : '';

  if (hour && min){
    return `${hour} ${min}`
  }
  else if (hour && !min){
    return `${hour}`
  }
  else if (!hour && min){
    return `${min}`
  }
  
};
