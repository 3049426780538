const latingReg =
  /^(((?<hm>\d+)\s*(?<type>[hm]))|((?<h>\d+)\s*h\s*(?<m>\d+)\s*m))$/
const cyrillycReg =
  /^(((?<hm>\d+)\s*(?<type>[чм]))|((?<h>\d+)\s*ч\s*(?<m>\d+)\s*м))$/

export const getMinutesFromString = (str = '') => {
  str = String(str)?.replace(',', '.')
  if (!isNaN(+str)) {
    return +str * 60
  }

  const isLatin = str.includes('h') || str.includes('m')
  const timeObj = str.trim().match(isLatin ? latingReg : cyrillycReg)?.groups

  if (timeObj?.type === 'ч' || timeObj?.type === 'h') {
    return Number(timeObj?.hm) * 60
  } else if (timeObj?.h && timeObj?.m) {
    return Number(timeObj?.h) * 60 + Number(timeObj?.m)
  }

  if (isNaN(+timeObj?.hm)) {
    return 0
  } else {
    return Number(timeObj?.hm)
  }
}
