import styled, { css } from 'styled-components'
import { PALETTE } from 'styles/constants'

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-bottom: 4px;
  background-color: ${PALETTE.paleGrey};
  border-radius: 50%;

  ${(p) =>
    p.size === 'large' &&
    css`
      width: 160px;
      height: 160px;
    `}

  &:not(:last-child) {
    margin-right: 4px;
  }
`
