import styled from 'styled-components'
import { PALETTE } from 'styles/constants'

export const ImageUploaderWrap = styled.div`
  width: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 28px;
  }
`

export const ImageUploaderLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${PALETTE.grey};
  margin-bottom: 16px;
`

export const ImageUploaderPicker = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${PALETTE.lightGrey};
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:focus {
    outline-color: ${PALETTE.primary};
  }

  &:hover::after,
  &:focus::after,
  &.is-dragging::after {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(48, 65, 86, 0.32);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(-636 -364)'%3E%3Crect width='24' height='24' transform='translate(636 364)' fill='none' /%3E%3Cpath d='M-18999-3383a2,2,0,0,1-2-2v-9a2,2,0,0,1,2-2h3v-2a1,1,0,0,1,1-1h6a.993.993,0,0,1,1,1v2h3a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2Z' transform='translate(19640 3766)' fill='none' stroke='%23fff' stroke-width='1.5' /%3E%3Ccircle cx='3' cy='3' r='3' transform='translate(645 373)' fill='none' stroke='%23fff' stroke-width='1.5' /%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
  }
`

export const ImageUploaderPreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`