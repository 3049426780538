import makeRequest from 'api/'
import getFilterParams from 'helpers/getFilterParams'
import { prepareStuff } from 'helpers/prepares'
import { sortTasksByLexorank } from '../../helpers/orderTasksByLexorank'

export const getProjectList = async (flag) => {
  const projects = await makeRequest({
    method: 'get',
    url: '/project',
    params: flag === 'all' ? { all: true } : {},
  })
  const preparedProjects = projects.data?.map((i) => ({
    ...i,
  }))

  return preparedProjects
}

export const createProject = async (data) => {
  return await makeRequest({
    method: 'post',
    url: '/project',
    data,
  })
}

export const deleteProject = async (slug) => {
  return await makeRequest({
    method: 'delete',
    url: `/project/${slug}`,
  })
}

export const updateProject = async (slug, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/project/${slug}`,
    data,
  })
}
export const getAllTasks = async (slug) => {
  return await makeRequest({
    method: 'get',
    url: `/project/${slug}/task`,
  })
}

export const getTaskList = async (slug, data, controller) => {
  const params = {}
  let filterParams = {}
  if (data.task) params['filter[name]'] = data.task
  if (data.without_epic) params['filter[without_epic]'] = data.without_epic
  if (data && Object.keys(data).length) {
    filterParams = getFilterParams(data, [
      'user_id',
      'component_id',
      'type_id',
      'priority_id',
    ])
  }

  for (let key in data) {
    if ((/date/.test(key) || /deadline/.test(key)) && data[key]) {
      params[`filter[${key}]`] = data[key]
    }
  }

  const tasks = await makeRequest({
    method: 'get',
    url: `/project/${slug}/task`,
    signal: controller ? controller.signal : null,
    params: {
      ...params,
      ...filterParams,
    },
  })

  let isRequestWithoutFitler
  if (
    Object.keys(params).length === 0 &&
    Object.keys(filterParams).length == 0
  ) {
    isRequestWithoutFitler = true
  }

  let allTasks
  if (!isRequestWithoutFitler) {
    allTasks = await getAllTasks(slug)
  }

  const tasksSorted = {
    data: tasks.data?.sort(sortTasksByLexorank),
  }

  return {
    task: tasksSorted,
    allTasks: isRequestWithoutFitler ? tasksSorted : allTasks,
  }
}

export const getProjectInfo = async (slug) => {
  const info = await makeRequest({
    method: 'get',
    url: `/project/${slug}`,
  })
  info.data.users = prepareStuff(info.data.users)
  return info
}
export const getProjectUsers = async (slug) => {
  return await makeRequest({
    method: 'get',
    url: `/project/${slug}/user`,
  })
}
export const updateProjectUser = async (slug, userId, roleId) => {
  return await makeRequest({
    method: 'patch',
    url: `/project/${slug}/user/${userId}`,
    data: { role_id: roleId },
  })
}

export const deleteProjectUser = async (slug, userId, roleId) => {
  return await makeRequest({
    method: 'delete',
    url: `/project/${slug}/user/${userId}`,
    data: { role_id: roleId },
  })
}
