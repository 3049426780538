import styled from 'styled-components'
import { Colors, Transition } from 'utils/constants'

export const SwitchCustom = styled.span`
  display: inline-block;
  position: relative;
  width: 32px;
  flex: 0 0 32px;
  height: 16px;
  border: 1px solid ${Colors.Black.TextSec};
  border-radius: 8px;
  transition: all ${Transition.ms300};
  background-color: ${Colors.Light.White};

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    background-color: ${Colors.Black.TextSec};
    border-radius: 50%;
    transition:
      transform ${Transition.ms300},
      background-color ${Transition.ms300};
  }

  &:hover {
    border-color: ${Colors.DS.MainBlue};
    background-color: ${Colors.Light.White};

    &::before {
      background-color: ${Colors.DS.MainBlue};
    }
  }
`

export const SwitchWrap = styled.label`
  display: flex;
  align-content: center;
  cursor: pointer;
  margin-bottom: 14px;
`

export const SwitchInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);

  &:checked ~ ${SwitchCustom} {
    background-color: ${Colors.DS.MainBlue};
    border-color: ${Colors.DS.MainBlue};

    &::before {
      background-color: ${Colors.Light.White};
      transform: translateX(16px);
    }

    &:hover {
      background-color: ${Colors.DS.MainBlue};
      border-color: ${Colors.DS.MainBlue};

      &::before {
        background-color: ${Colors.Light.White};
      }
    }
  }
`

const SwitchLabelStyled = styled.span`
  display: block;
  margin-left: 8px;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.Black.IconsSidebar};
`

export const SwitchLabel = ({ children }) => {
  return <SwitchLabelStyled>{children}</SwitchLabelStyled>
}
