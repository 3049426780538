import { File, FileTitle, FileDelete } from './styled'
import Icon from '../Icon'

const FileChip = ({
  title,
  canDelete = false,
  link,
  fileId,
  deleteHandler,
}) => {
  return (
    <File href={link}>
      <FileTitle>{title}</FileTitle>
      {canDelete && (
        <FileDelete
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            deleteHandler(fileId)
          }}
        >
          <Icon name="close" />
        </FileDelete>
      )}
    </File>
  )
}

export default FileChip
