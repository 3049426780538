import { useState } from 'react'
import ControlButton from 'ui/components/ControlButton'
import Select from 'ui/components/forms/Select/'
import SelectWithCheckboxes from 'ui/components/forms/SelectWithCheckboxes'
import { notifyError } from 'ui/components/Notify'
import {
  AddButtonWrap,
  DeleteButtonWrap,
  SelectCol,
  SelectRow,
  SelectWrapper,
} from './styled'

export function SelectNewUser({
  userList,
  roleList,
  updateProjectUserMutation,
  updateUserList,
  slug,
}) {
  const [newUser, setNewUser] = useState(null)

  const addUser = () => {
    if (!newUser?.userId || !newUser?.roleId) {
      return notifyError('Введите имя пользователя или роль')
    }

    newUser.roleId.forEach((role) =>
      updateProjectUserMutation({
        slug,
        userId: newUser?.userId,
        roleId: role,
      })
    )

    setNewUser(null)
  }

  return (
    <>
      {newUser && (
        <SelectRow>
          <SelectCol>
            <SelectWrapper>
              <Select
                label="Имя"
                id={`nameSelect`}
                options={userList}
                onChange={(value) =>
                  setNewUser((user) => ({ ...user, userId: value.id }))
                }
                placeholder="Имя"
                maxHeight="200px"
                defaultValue={''}
              />
            </SelectWrapper>
          </SelectCol>
          <SelectCol>
            <SelectWithCheckboxes
              label="Роль"
              id={`roleSelect`}
              required
              options={roleList}
              onChange={(values) =>
                setNewUser((user) => ({
                  ...user,
                  roleId: values.map((value) => value.id),
                }))
              }
              placeholder="Роль"
              defaultValue={''}
            />
          </SelectCol>
          <DeleteButtonWrap>
            <ControlButton theme="edit" onClick={addUser}>
              Подтвердить
            </ControlButton>
          </DeleteButtonWrap>
        </SelectRow>
      )}

      {!newUser && (
        <AddButtonWrap>
          <ControlButton
            theme="base"
            onClick={() => {
              setNewUser({})
              updateUserList()
            }}
          >
            + Добавить сотрудника
          </ControlButton>
        </AddButtonWrap>
      )}
    </>
  )
}
