import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  clearCreateTagStatus,
  clearDeleteTagStatus,
  createTagAction,
  createTagStatusSelector,
  deleteTagStatusSelector,
  getTagListAction,
  tagListSelector,
} from 'store/dictionaries'
import { closeTagModal, tagModalSelector } from 'store/modals'
import Button from 'ui/components/buttons/Button'
import Input from 'ui/components/forms/Input'
import ModalBasic from 'ui/components/ModalBasic'
import Tag from 'ui/components/Tag'
import { ButtonCell, InputWrap, NewTagWrapper, TagListWrapper } from './styled'

const TagModal = ({}) => {
  const dispatch = useDispatch()
  const { open } = useSelector(tagModalSelector)
  const tagList = useSelector(tagListSelector)
  const createTagStatus = useSelector(createTagStatusSelector)
  const deleteTagStatus = useSelector(deleteTagStatusSelector)

  const formik = useFormik({
    initialValues: {
      tag: '',
    },
    validationSchema: Yup.object({
      tag: Yup.string().min(1, 'Нужно заполнить').required('Нужно заполнить'),
    }),
    onSubmit: (values) => {
      dispatch(createTagAction(values))
      formik.resetForm()
    },
  })

  useEffect(() => {
    if (createTagStatus === 'success') {
      dispatch(getTagListAction())
      dispatch(clearCreateTagStatus())
    }
  }, [createTagStatus])

  useEffect(() => {
    if (deleteTagStatus === 'success') {
      dispatch(getTagListAction())
      dispatch(clearDeleteTagStatus())
    }
  }, [deleteTagStatus])

  const handlerClose = () => {
    dispatch(closeTagModal())
  }

  return (
    <ModalBasic
      size={'small'}
      open={open}
      handlerClose={handlerClose}
      title={'Теги'}
    >
      <NewTagWrapper>
        <InputWrap>
          <Input
            id="addMarkupLinkInput"
            label="Имя"
            name="tag"
            meta={formik.getFieldMeta('tag')}
            {...formik.getFieldProps('tag')}
          />
        </InputWrap>
        <ButtonCell>
          <Button onClick={formik.handleSubmit} title="Создать тег" />
        </ButtonCell>
      </NewTagWrapper>
      <TagListWrapper>
        {tagList?.map((i) => {
          return <Tag key={i.id} data={i} />
        })}
      </TagListWrapper>
    </ModalBasic>
  )
}

export default TagModal
