import { ButtonStyled, ButtonLoader, ButtonTitle } from './styled'

const Button = ({
  theme = 'base',
  minWidth,
  title,
  onClick,
  icon,
  loading = '',
  attrTitle,
  ...props
}) => (
  <ButtonStyled
    theme={theme}
    minWidth={minWidth}
    onClick={onClick}
    loading={String(loading)}
    title={attrTitle}
    {...props}
  >
    {icon && icon}
    {loading && <ButtonLoader />}
    {title && <ButtonTitle>{title}</ButtonTitle>}
  </ButtonStyled>
)

export default Button
