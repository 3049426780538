import { addDays, parse } from 'date-fns'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import * as Yup from 'yup'
import { checkIsFieldRequired } from 'helpers/checkIsFieldRequired'
import formatTimeBlur from 'helpers/formatTimeBlur'
import { formatToTime } from 'helpers/formatToTime'
import isValidURL from 'helpers/isValidURL'
import {
  prepareUserList,
  prepareCommonSelects,
  prepareTaskValues,
  prepareUserIdList,
} from 'helpers/prepares'
import removeDuplicates from 'helpers/removeDuplicates'
import { setDateValue } from 'helpers/setDateValue'
import { usePrevilegies, usePrevilegiesGlobal } from 'hooks/usePrevilegies'
import { TASK_TYPE } from 'store/constants'
import { taskTypesSelector, prioritiesSelector } from 'store/dictionaries'
import { closeTaskEdit, taskEditModalSelector } from 'store/modals'
import {
  allTasksSelector,
  getAllTasksAction,
  projectInfoSelector,
} from 'store/projects'
import {
  taskDetailSelector,
  updateTaskStatusSelector,
  updateTaskAction,
} from 'store/tasks'
import { currentUserSelector } from 'store/users'
import { ScreenSize } from 'styles/constants'
import Button from 'ui/components/buttons/Button/'
import ConfirmModal from 'ui/components/ConfirmModal'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import { EditorWrapper } from 'ui/components/forms/EditorWrapper/'
import Input from 'ui/components/forms/Input/'
import Select from 'ui/components/forms/Select/'
import SelectWithCheckboxes from 'ui/components/forms/SelectWithCheckboxes'
import ModalBasic from 'ui/components/ModalBasic'
// import TaskGradeItems from '../TaskGradeItems'
import { FieldGroup, Buttons, DateGroup, DateItem } from './styled'

const TaskEdit = ({}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { projectSlug } = router.query
  const isMobile = useMedia(ScreenSize.mobile)
  const { defaultType, open, title } = useSelector(taskEditModalSelector)
  const handlerClose = () => {
    setConfirmOpened(false)
    dispatch(closeTaskEdit())
  }
  const taskList = useSelector(allTasksSelector)

  const epicsList = useSelector(allTasksSelector)?.filter(
    (i) => i.task_type === TASK_TYPE.Epic
  )
  const preparedEpicList = prepareCommonSelects(epicsList, true)

  const priorities = useSelector(prioritiesSelector)
  const taskTypes = useSelector(taskTypesSelector)
  const taskDetail = useSelector(taskDetailSelector)
  const projectInfo = useSelector(projectInfoSelector)
  const currentUser = useSelector(currentUserSelector)

  const { isManager } = usePrevilegies()
  const { isManagerGlobal } = usePrevilegiesGlobal()

  const preparedTaskList = prepareCommonSelects(
    taskList?.filter((i) => i.id !== taskDetail?.id),
    true
  )

  const canAssignWorkers = useMemo(
    () => taskDetail?.capabilities?.includes('assign_workers'),
    [taskDetail]
  )

  const componentsList = useMemo(() => {
    return projectInfo
      ? prepareCommonSelects(projectInfo.flow.possibleProjectComponents)
      : []
  }, [projectInfo])

  const [visibleTaskFields, setVisibleTaskFields] = useState([])

  useEffect(() => {
    if (projectInfo) {
      setVisibleTaskFields(
        () =>
          projectInfo?.flow?.task_type_fields_map?.find(
            (i) => i.task_type_id === taskDetail?.task_type?.id
          )?.fields
      )
    }
  }, [projectInfo])

  const [requiredFields, setRequiredFields] = useState({})
  const [pending, setPending] = useState(false)

  const priorityList = prepareCommonSelects(priorities)
  const taskTypesList = useMemo(() => {
    const transformedTypesList =
      taskTypes?.length > 0
        ? prepareCommonSelects(taskTypes).filter(
            (item) => item.id !== TASK_TYPE.Backlog
          )
        : []

    return transformedTypesList
  }, [taskTypes])

  const preparedExecutorsList = prepareUserList(
    removeDuplicates(projectInfo?.users || [])
  )

  const executors = useMemo(() => {
    if (projectInfo?.perm_user_self_assign && !isManager) {
      return preparedExecutorsList?.filter((i) => i.id === currentUser?.id)
    } else {
      return preparedExecutorsList
    }
  })

  const taskStatus = useSelector(updateTaskStatusSelector)

  useEffect(() => {
    if (taskStatus !== 'pending') {
      setPending(false)
    }
  }, [taskStatus])

  const startRelations = {
    block:
      taskDetail?.block?.length > 0
        ? prepareCommonSelects(
            taskList?.filter((i) =>
              taskDetail?.block?.map((i) => i.id).includes(i.id)
            )
          )
        : [],
    related:
      taskDetail?.related?.length > 0
        ? prepareCommonSelects(
            taskList?.filter((i) =>
              taskDetail?.related?.map((i) => i.id).includes(i.id)
            )
          )
        : [],
    release_id: taskDetail?.release?.id
      ? prepareCommonSelects(
          taskList?.filter((i) => i.id === taskDetail?.release.id)[0]
        )
      : '',
    epic_id: taskDetail?.epic?.id
      ? prepareCommonSelects(
          taskList?.filter((i) => i.id === taskDetail?.epic.id)[0]
        )
      : '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: taskDetail?.name || undefined,
      stage_id: taskDetail?.stage?.id || undefined,
      task_type_id: defaultType
        ? prepareCommonSelects(taskTypes?.find((i) => i.id === defaultType))
        : prepareCommonSelects(taskDetail?.task_type) || undefined,
      estimate_worker: formatToTime(taskDetail?.estimate_worker).replace(
        /\s/g,
        ''
      ),
      estimate_cost:
        formatToTime(taskDetail?.estimate_cost).replace(/\s/g, '') || undefined,
      executors: prepareUserList(taskDetail?.users) || undefined,
      component_id: taskDetail?.component
        ? prepareCommonSelects(taskDetail?.component)
        : undefined,
      priority_id: taskDetail?.priority
        ? prepareCommonSelects(taskDetail?.priority)
        : prepareCommonSelects(priorities[1]),
      layout_link: taskDetail?.layout_link
        ? taskDetail?.layout_link
        : undefined,
      markup_link: taskDetail?.markup_link
        ? taskDetail?.markup_link
        : undefined,
      dev_link: taskDetail?.dev_link ? taskDetail?.dev_link : undefined,
      description: taskDetail?.description
        ? taskDetail?.description
        : undefined,
      //svyazi
      block: startRelations.block || undefined,
      related: startRelations.related || undefined,
      release_id: startRelations.release_id || undefined,
      epic_id: startRelations.epic_id || undefined,

      date_start: taskDetail?.date_start || null,
      date_end: taskDetail?.date_end || null,
      ...(isManager ? { deadline: taskDetail?.deadline || null } : {}),
      deadline: taskDetail?.deadline || null,
      //grades
      // grade_items:
      //   currentUser?.is_admin || currentUser?.can_grade
      //     ? taskDetail?.grade_items || null
      //     : null,
    },
    validationSchema: Yup.object({
      name: visibleTaskFields?.includes('name')
        ? Yup.string()
            .max(200, 'Название не должно превышать 200 символов')
            .required('Нужно заполнить')
        : Yup.string(),
      task_type_id: visibleTaskFields?.includes('task_type')
        ? Yup.object().required('Нужно заполнить')
        : Yup.object(),
      estimate_worker: visibleTaskFields?.includes('estimate_worker')
        ? Yup.string()
        : Yup.string() || Yup.number(),
      estimate_cost: visibleTaskFields?.includes('estimate_cost')
        ? Yup.string()
        : Yup.string() || Yup.number(),
      executors:
        visibleTaskFields?.includes('users') &&
        taskDetail?.task_type?.id !== taskTypes.Epic
          ? Yup.array().min(1, 'Нужно заполнить')
          : Yup.array(),
      component_id:
        visibleTaskFields?.includes('component') &&
        checkIsFieldRequired('component_id', requiredFields)
          ? Yup.object().required('Нужно заполнить')
          : Yup.object(),
      priority_id: visibleTaskFields?.includes('priority')
        ? Yup.object().required('Нужно заполнить')
        : Yup.object(),
      description: checkIsFieldRequired('description', requiredFields)
        ? Yup.string().min(1, 'Нужно заполнить').required('Нужно заполнить')
        : Yup.string(),
      layout_link: visibleTaskFields?.includes('layout_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      markup_link: visibleTaskFields?.includes('markup_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      dev_link: visibleTaskFields?.includes('dev_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      //svyazi
      block: Yup.array(),
      related: Yup.array(),
      release_id: Yup.object(),
      epic_id: Yup.object(),
      date_start: Yup.string().nullable(true).notRequired(),
      date_end: Yup.string().nullable(true).notRequired(),
      deadline: Yup.string().nullable(true).notRequired(),
      //grades
      // grade_items: Yup.array().nullable(true).notRequired(),
    }),
    onSubmit: async (values) => {
      if (
        values.description.replace('<p>', '').replace('</p>', '').length > 0
      ) {
        const preparedValues = prepareTaskValues(values)
        const executorsIdList = prepareUserIdList(preparedValues.executors)
        const blockIds = values.block?.map((i) => i.id)
        const relatedIds = values.related?.map((i) => i.id)
        const typeId = values.task_type_id.id
        const dates = {
          date_start: values.date_start || null,
          date_end: values.date_end || null,
        }

        if (!(isManagerGlobal || isManager)) {
          Object.defineProperty(dates, 'deadline', {
            value: values.deadline,
          })
        }

        setPending(true)

        preparedValues.estimate_worker = preparedValues.estimate_worker
          ? preparedValues.estimate_worker
          : undefined
        preparedValues.estimate_cost = preparedValues.estimate_cost
          ? preparedValues.estimate_cost
          : undefined
        dispatch(
          updateTaskAction({
            id: taskDetail?.id,
            values:
              typeId === TASK_TYPE.Epic
                ? {
                    ...preparedValues,
                    estimate_cost:
                      values.estimate_cost === ''
                        ? null
                        : preparedValues.estimate_cost,
                    estimate_worker:
                      values.estimate_worker === ''
                        ? null
                        : preparedValues.estimate_worker,
                    executors: [],
                    components: [],
                    block: null,
                    related: null,
                    ...dates,
                  }
                : {
                    ...preparedValues,
                    block: blockIds,
                    related: relatedIds,
                    executors: executorsIdList || [],
                    estimate_cost:
                      values.estimate_cost === ''
                        ? null
                        : preparedValues.estimate_cost,
                    estimate_worker:
                      values.estimate_worker === ''
                        ? null
                        : preparedValues.estimate_worker,
                    ...dates,
                  },
          })
        )
      } else {
        formik.setFieldError('description', 'Нужно заполнить')
      }
    },
  })

  useEffect(() => {
    setVisibleTaskFields(
      projectInfo?.flow?.task_type_fields_map?.find(
        (i) => i.task_type_id === formik.values.task_type_id.id
      )?.fields
    )
    handleChangeTaskType(formik?.values.task_type_id.id)
  }, [formik?.values.task_type_id])

  useEffect(() => {
    if (open) {
      dispatch(getAllTasksAction(projectSlug))
    }
  }, [open])

  const handleChangeTaskType = (taskTypeId) => {
    switch (taskTypeId) {
      // Эпик
      case TASK_TYPE.Epic:
        setRequiredFields({
          description: false,
        })
        break
      // Бэклог
      case TASK_TYPE.Backlog:
        setRequiredFields({
          component_id: false,
          description: false,
        })
        break
      default:
        setRequiredFields({})
        break
    }
  }

  const startDateHasValue = Boolean(formik.getFieldMeta('date_start').value)

  useEffect(() => {
    if (!startDateHasValue) {
      formik.setFieldValue('date_end', null)
    }
  }, [startDateHasValue])
  const [confirmOpened, setConfirmOpened] = useState(false)

  return (
    <ModalBasic
      size={isMobile ? 'fullscreen' : 'large'}
      title={title}
      open={open}
      handlerClose={() => setConfirmOpened(true)}
    >
      <div>
        <ConfirmModal
          title="Закрыть окно?"
          open={confirmOpened}
          confirmHandler={handlerClose}
          handlerClose={() => setConfirmOpened(false)}
        />
        <form onSubmit={formik.handleSubmit} noValidate>
          {visibleTaskFields?.includes('name') && (
            <Input
              required={true}
              id="editNameInput"
              label="Название"
              name="name"
              meta={formik.getFieldMeta('name')}
              {...formik.getFieldProps('name')}
            />
          )}
          <FieldGroup count={3}>
            {visibleTaskFields?.includes('task_type') && (
              <Select
                id="editTypeSelect"
                required={checkIsFieldRequired('task_type', requiredFields)}
                options={taskTypesList}
                value={
                  formik.getFieldMeta('task_type_id')
                    ? formik.getFieldMeta('task_type_id').value
                    : null
                }
                meta={formik.getFieldMeta('task_type_id')}
                error={
                  formik.getFieldMeta('task_type_id').touched &&
                  formik.getFieldMeta('task_type_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('task_type_id', newValue)
                  } else {
                    formik.setFieldValue('task_type_id', '')
                  }
                }}
                label="Тип задачи"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('component') && (
              <Select
                id="editComponentSelect"
                required={checkIsFieldRequired('component_id', requiredFields)}
                options={componentsList}
                value={
                  formik.getFieldMeta('component_id')
                    ? formik.getFieldMeta('component_id').value
                    : null
                }
                meta={formik.getFieldMeta('component_id')}
                error={
                  formik.getFieldMeta('component_id').touched &&
                  formik.getFieldMeta('component_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('component_id', newValue)
                  } else {
                    formik.setFieldValue('component_id', '')
                  }
                }}
                label="Компонент"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('users') && (
              <SelectWithCheckboxes
                id="editExecutorsSelect"
                isDisabled={!canAssignWorkers}
                required={checkIsFieldRequired('executors', requiredFields)}
                options={executors}
                value={
                  formik.getFieldMeta('executors')
                    ? formik.getFieldMeta('executors').value
                    : null
                }
                meta={formik.getFieldMeta('executors')}
                error={
                  formik.getFieldMeta('executors').touched &&
                  formik.getFieldMeta('executors').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('executors', newValue)
                  } else {
                    formik.setFieldValue('executors', '')
                  }
                }}
                label="Исполнитель"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('priority') && (
              <Select
                id="editPrioritySelect"
                required={checkIsFieldRequired('priority_id', requiredFields)}
                options={priorityList}
                value={
                  formik.getFieldMeta('priority_id')
                    ? formik.getFieldMeta('priority_id').value
                    : null
                }
                meta={formik.getFieldMeta('priority_id')}
                error={
                  formik.getFieldMeta('priority_id').touched &&
                  formik.getFieldMeta('priority_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('priority_id', newValue)
                  } else {
                    formik.setFieldValue('priority_id', '')
                  }
                }}
                label="Приоритет"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}

            {visibleTaskFields?.includes('estimate_worker') && (
              <Input
                id="editEstimateWorkerInput"
                label="Оценка"
                name="estimate_worker"
                meta={formik.getFieldMeta('estimate_worker')}
                {...formik.getFieldProps('estimate_worker')}
                onBlur={(event) =>
                  formatTimeBlur(event, formik, 'estimate_worker')
                }
              />
            )}
            {visibleTaskFields?.includes('estimate_cost') && (
              <Input
                id="editEstimateCostInput"
                label="Оценка по смете"
                name="estimate_cost"
                meta={formik.getFieldMeta('estimate_cost')}
                {...formik.getFieldProps('estimate_cost')}
                onBlur={(event) =>
                  formatTimeBlur(event, formik, 'estimate_cost')
                }
              />
            )}
          </FieldGroup>

          <DateGroup>
            <DateItem>
              <DatepickerInput
                id="dateStartPicker"
                autoComplete="off"
                label="Дата начала"
                isClearable
                meta={formik.getFieldMeta('date_start')}
                selected={
                  formik.getFieldMeta('date_start').value
                    ? parse(
                        formik.getFieldMeta('date_start').value,
                        'dd.MM.yyyy',
                        new Date()
                      )
                    : null
                }
                onChange={(date) => setDateValue(formik, 'date_start', date)}
                instanceId="datepicker"
              />
            </DateItem>

            <DateItem>
              <DatepickerInput
                id="dateEndPicker"
                autoComplete="off"
                label="Дата завершения"
                isClearable
                minDate={
                  formik.getFieldMeta('date_start').value
                    ? addDays(
                        parse(
                          formik.getFieldMeta('date_start').value,
                          'dd.MM.yyyy',
                          new Date()
                        ),
                        1
                      )
                    : null
                }
                meta={formik.getFieldMeta('date_end')}
                selected={
                  formik.getFieldMeta('date_end').value
                    ? parse(
                        formik.getFieldMeta('date_end').value,
                        'dd.MM.yyyy',
                        new Date()
                      )
                    : null
                }
                onChange={(date) => setDateValue(formik, 'date_end', date)}
                instanceId="datepicker"
              />
            </DateItem>

            {(isManagerGlobal || isManager) && (
              <DateItem>
                <DatepickerInput
                  id="timePicker"
                  autoComplete="off"
                  label="Дедлайн"
                  meta={formik.getFieldMeta('deadline')}
                  isClearable
                  selected={
                    formik.getFieldMeta('deadline').value
                      ? parse(
                          formik.getFieldMeta('deadline').value,
                          'dd.MM.yyyy',
                          new Date()
                        )
                      : null
                  }
                  onChange={(date) => setDateValue(formik, 'deadline', date)}
                  instanceId="datepicker"
                />
              </DateItem>
            )}
          </DateGroup>

          {visibleTaskFields?.includes('description') && (
            <EditorWrapper
              editorVersion={taskDetail?.editor_version}
              required={checkIsFieldRequired('description', requiredFields)}
              label="Введите текст"
              name="description"
              meta={formik.getFieldMeta('description')}
              value={formik.getFieldProps('description').value}
              onChange={(value) => {
                formik.setFieldValue('description', value)
              }}
            />
          )}
          <FieldGroup count={4}>
            {visibleTaskFields?.includes('block') && (
              <SelectWithCheckboxes
                id="editBlockSelect"
                isClearable={true}
                options={preparedTaskList}
                value={
                  formik.getFieldMeta('block')
                    ? formik.getFieldMeta('block').value
                    : null
                }
                meta={formik.getFieldMeta('block')}
                error={
                  formik.getFieldMeta('block').touched &&
                  formik.getFieldMeta('block').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('block', newValue)
                  } else {
                    formik.setFieldValue('block', '')
                  }
                }}
                label="Блокирует задачу"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('related') && (
              <SelectWithCheckboxes
                id="editRelatedSelect"
                isClearable={true}
                options={preparedTaskList}
                value={
                  formik.getFieldMeta('related')
                    ? formik.getFieldMeta('related').value
                    : null
                }
                meta={formik.getFieldMeta('related')}
                error={
                  formik.getFieldMeta('related').touched &&
                  formik.getFieldMeta('related').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('related', newValue)
                  } else {
                    formik.setFieldValue('related', '')
                  }
                }}
                label="Связана с"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('release') && (
              <Select
                id="editReleaseSelect"
                isClearable={true}
                options={preparedTaskList}
                value={
                  formik.getFieldMeta('release_id')
                    ? formik.getFieldMeta('release_id').value
                    : null
                }
                meta={formik.getFieldMeta('release_id')}
                error={
                  formik.getFieldMeta('release_id').touched &&
                  formik.getFieldMeta('release_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('release_id', newValue)
                  } else {
                    formik.setFieldValue('release_id', '')
                  }
                }}
                label="В релизе"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
            {visibleTaskFields?.includes('epic') && (
              <Select
                id="editEpicSelect"
                isClearable={true}
                options={preparedEpicList}
                value={
                  formik.getFieldMeta('epic_id')
                    ? formik.getFieldMeta('epic_id').value
                    : null
                }
                meta={formik.getFieldMeta('epic_id')}
                error={
                  formik.getFieldMeta('epic_id').touched &&
                  formik.getFieldMeta('epic_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('epic_id', newValue)
                  } else {
                    formik.setFieldValue('epic_id', '')
                  }
                }}
                label="Относится к эпику"
                defaultTitle="Любой"
                maxHeight="174px"
              />
            )}
          </FieldGroup>
          <FieldGroup count={3}>
            {visibleTaskFields?.includes('layout_link') && (
              <Input
                id="editLayoutLinkInput"
                label="Layout Link"
                name="layout_link"
                meta={formik.getFieldMeta('layout_link')}
                {...formik.getFieldProps('layout_link')}
              />
            )}
            {visibleTaskFields?.includes('markup_link') && (
              <Input
                id="editMarkupLinkInput"
                label="Markup Link"
                name="markup_link"
                meta={formik.getFieldMeta('markup_link')}
                {...formik.getFieldProps('markup_link')}
              />
            )}
            {visibleTaskFields?.includes('dev_link') && (
              <Input
                id="editDevLinkInput"
                label="Dev Link"
                name="dev_link"
                meta={formik.getFieldMeta('dev_link')}
                {...formik.getFieldProps('dev_link')}
              />
            )}
          </FieldGroup>
          {/* {visibleTaskFields?.includes('grade_items') && (
            <TaskGradeItems formik={formik} />
          )} */}
          <Buttons>
            <Button title="Сохранить" type="submit" loading={pending} />
            <Button
              title="Отменить"
              theme="borderGrey"
              type="reset"
              onClick={() => setConfirmOpened(true)}
            />
          </Buttons>
        </form>
      </div>
    </ModalBasic>
  )
}

export default TaskEdit
