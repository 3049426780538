import styled from 'styled-components'
import { BREAKPOINTS, PALETTE } from 'styles/constants'
import { Colors } from 'utils'

export const Wrapper = styled.div`
  position: relative;
`

export const Dragzone = styled.div`
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${PALETTE.grey};
  border: 1px dashed ${Colors.Black.BlueGrey};
  border-radius: 6px;

  svg {
    margin-right: 10px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 10px;
    font-size: 12px;
  }
`

export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  &:empty {
    display: none;
  }
`

export const FileCard = styled.a`
  // width: calc(100% / 4 - 15px);
  height: 160px;
  font-size: 12px;
  color: #999;
  background: ${PALETTE.white};
  border: 1px solid ${PALETTE.lightGrey};
  padding: 15px;
  margin: 0 20px 20px 0;
  position: relative;
  border-radius: 4px;
  transition: 300ms ease-out;

  &:nth-child(n + 4) {
    margin-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: calc(100% / 2 - 10px);

    &:nth-child(even) {
      margin-right: 0px;
    }
  }
`

export const FileDelete = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  right: 2px;
  color: ${PALETTE.grey};
  background: transparent;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover {
    color: ${PALETTE.popPink};
  }
`

export const FileWatch = styled.a`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  right: 0;
  fill: ${PALETTE.grey};
  background: transparent;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover {
    fill: ${PALETTE.popPink};
  }

  svg {
    width: 17px;
    height: 17px;
  }
`

export const FileActionsContainer = styled.div`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 0;
  right: 0;
`

export const FileImage = styled.div`
  height: 75px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  &:empty {
    background-image: url('/images/icons/doc-text-fill.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: ${Colors.Light.White};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const FileInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FileTitle = styled.div`
  width: 100%;
  color: ${PALETTE.primary};
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
`

export const FileDate = styled.div``
