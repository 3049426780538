import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { themeSelector, toggleTheme } from 'store/users'
import Sidebar from 'ui/components/Sidebar/'
import { Logo } from 'ui/components/Sidebar/components'
import Icon from '../Icon'
import { HeaderBar, HeaderBurger } from './styled'

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false)
  const theme = useSelector(themeSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    const value = localStorage.getItem('theme')
    dispatch(toggleTheme(value))
  }, [])

  return (
    <>
      <HeaderBar>
        <Link href="/">
          <a>
            <Logo />
          </a>
        </Link>
        <HeaderBurger onClick={() => setMenuOpened(!menuOpened)}>
          {menuOpened ? (
            <Icon name="menu-close" size="40" />
          ) : (
            <Icon name="burger" size="40" />
          )}
        </HeaderBurger>
      </HeaderBar>
      <Sidebar
        opened={menuOpened}
        setMenuOpened={setMenuOpened}
        theme={theme}
      />
    </>
  )
}

export default Header
