import makeRequest from 'api/'

export const getTaskDetail = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/task/${id}`,
  })
}

export const taskFileAttach = async (id, fileId) => {
  return await makeRequest({
    method: 'patch',
    url: `/task/${id}/file/${fileId}`,
  })
}

export const taskFileDelete = async (id, fileId) => {
  return await makeRequest({
    method: 'delete',
    url: `/task/${id}/file/${fileId}`,
  })
}

export const taskCommentSend = async (id, data) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/comment`,
    data,
  })
}
export const taskCommentUpdate = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/comment/${id}`,
    data,
  })
}

export const taskCommentFileAttach = async (commentId, fileId) => {
  return await makeRequest({
    method: 'patch',
    url: `/comment/${commentId}/file/${fileId}`,
  })
}

export const taskCommentFileDelete = async (commentId, fileId) => {
  return await makeRequest({
    method: 'delete',
    url: `/comment/${commentId}/file/${fileId}`,
  })
}

export const taskCommentDelete = async (commentId) => {
  return await makeRequest({
    method: 'delete',
    url: `/comment/${commentId}`,
  })
}

export const getTaskWork = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/task/${id}/work`,
  })
}

export const addWorkTime = async (id, data) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/work`,
    data,
  })
}

export const updateTask = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/task/${id}/`,
    data,
  })
}

export const deleteTask = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/task/${id}/`,
  })
}

export const releaseTask = async (id) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/release`,
  })
}

export const toTestTask = async (id) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/test`,
  })
}

export const updateTaskUser = async (taskId, userId) => {
  return await makeRequest({
    method: 'patch',
    url: `/task/${taskId}/user/${userId}`,
  })
}

export const createProjectTask = async (slug, data) => {
  return await makeRequest({
    method: 'post',
    url: `/project/${slug}/task`,
    data,
  })
}

export const moveTaskToBacklog = async (id) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/backlog`,
  })
}

export const transferTask = async (id, data) => {
  return await makeRequest({
    method: 'post',
    url: `/task/${id}/transfer`,
    data,
  })
}
