import {
  newLineSymbol,
  regLastSymbolIsSpace,
  regNewLineSymbol,
  regSubListLineSymbol,
  regSubListSymbol,
} from 'helpers/constants/reg'
import {
  cursorPast,
  findNewLineSymbol,
  getArea,
  getSelectedLine,
  getStartLineIndex,
} from './helpers'

export const addTextStyle = (value, style, areaId) => {
  const { area, startPos, endPos } = getArea(areaId)
  const { selectedValue, stringsArr } = getSelectedLine(value, startPos, endPos)
  let valueArr = value?.split('')
  let symbol
  let endPlus

  switch (style) {
    case 'bold':
      symbol = '*'
      endPlus = 1
      break
    case 'italic':
      symbol = '_'
      endPlus = 1
  }

  if (!!selectedValue.match(regNewLineSymbol)) {
    // при выделении нескольких строк
    const preparedValue = stringsArr
      .map((str, index) => {
        const strArr = str.split('')

        strArr.unshift(symbol)
        strArr.push(symbol)

        if (index < stringsArr.length - 1) {
          strArr.push(newLineSymbol)
        }

        return strArr.join('')
      })
      .join('')

    endPlus = stringsArr.length * 2 - 1 // переносим курсор в конец выделенного текста перед закрывающимся последним '**' 2 - количество символов в каждой строке. 1 - минус последний закрывающий спецсимвол '**'
    valueArr.splice(startPos, selectedValue.length, preparedValue)
  } else {
    if (startPos !== endPos) {
      selectedValue.match(regLastSymbolIsSpace) ? (endPlus -= 1) : null // на случай, когда выделяют слово и захватывают пробел в конце

      valueArr.splice(startPos, 0, symbol)
      valueArr.splice(endPos + endPlus, 0, symbol)
    } else {
      valueArr.splice(startPos, 0, symbol)
      valueArr.splice(startPos + endPlus, 0, symbol)
    }
  }

  area.focus()
  cursorPast({
    area,
    startPos,
    endPos,
    endPlus,
  })

  return {
    newValue: valueArr.join(''),
  }
}

export const addQuotesStyle = (value) => {
  const { area, startPos, endPos } = getArea()
  const countPos = endPos - startPos
  let valueArr = value?.split('')
  let endPlus = 4
  let symbol = 'bq. '

  if (startPos !== endPos) {
    const haveNLSABefore = findNewLineSymbol(value, startPos - 2, startPos - 1)
    const haveNLSAfter = findNewLineSymbol(value, endPos + 1, endPos + 2)
    const selectedValue = value.slice(startPos, endPos)
    let preparedValue

    if (haveNLSABefore && haveNLSAfter) {
      preparedValue = [symbol, selectedValue]
    } else if (haveNLSABefore && !haveNLSAfter) {
      preparedValue = [symbol, selectedValue, newLineSymbol]
    } else if (haveNLSAfter && !haveNLSABefore) {
      preparedValue = [newLineSymbol, symbol, selectedValue]
    } else {
      endPlus *= 2
      preparedValue = [newLineSymbol, symbol, selectedValue, newLineSymbol]
    }

    valueArr.splice(startPos, countPos, preparedValue.join(''))
  } else {
    const { startLineIndex } = getStartLineIndex(value, startPos) // начало строки, на которой находится курсор
    valueArr.splice(startLineIndex, 0, symbol)
  }

  area.focus()
  cursorPast({
    area,
    startPos,
    endPos,
    endPlus,
  })

  return {
    newValue: valueArr.join(''),
  }
}

export const addListStyle = (value, type, areaId) => {
  const { area, startPos, endPos } = getArea(areaId)
  const countPos = endPos - startPos
  const { stringsArr } = getSelectedLine(value, startPos, endPos)
  let valueArr = value?.split('')
  let endPlus = 0
  let symbol

  switch (type) {
    case 'numbered':
      symbol = '#'
      break
    case 'marker':
      symbol = '*'
  }

  const haveNLSymbol = findNewLineSymbol(value, endPos + 1, endPos + 2) // находим, если ли после списка перенос строки
  endPlus = stringsArr.length

  if (startPos !== endPos) {
    let preparedValue = stringsArr
      .map((line, index) => {
        const isList = line.match(regSubListLineSymbol) // проверяем является ли строка уже списком
        const newList = `${symbol} ${line}` // подготавливаем оба варианта
        const nestedList = line.match(regSubListSymbol)
        const nestedListLength = nestedList && nestedList[0].length
        let newLine
        let newSubList
        let preparedListLine

        if (isList) {
          newLine = line
            .split('')
            .map((char, index) => {
              if (index < nestedListLength) {
                return symbol
              }
              return char
            })
            .join('')

          newSubList = `${symbol}${newLine}` // заменяем спецсимволы на случай вложенности разных типов списков
          preparedListLine = newSubList
        } else {
          endPlus += 1 // если это подсписок, то нужно учесть отступ для старого и нового спецсимволов
          preparedListLine = newList
        }

        if (stringsArr.length - 1 === index && haveNLSymbol) {
          // не добавляем перенос строки, если это последняя строка и после всего списка уже есть отступ
          return preparedListLine
        }

        return `${preparedListLine}${newLineSymbol}`
      })
      .join('')

    valueArr.splice(startPos, countPos, preparedValue)
  } else {
    const { startLineIndex } = getStartLineIndex(value, startPos) // начало строки, на которой находится курсор
    const curLine = valueArr.slice(startLineIndex, startPos).join('') // сама строка, на которой находится курсор
    const curLineIsList = !!curLine.match(regSubListLineSymbol)
    const preparedListItem = curLineIsList ? symbol : `${symbol} `
    endPlus = 2

    valueArr.splice(startLineIndex, 0, preparedListItem)
  }

  area.focus()
  cursorPast({
    area,
    startPos,
    endPos,
    endPlus,
  })

  return {
    newValue: valueArr.join(''),
  }
}

export const addCodeStyle = (value, areaId) => {
  const { area, startPos, endPos } = getArea(areaId)
  const symbolStart = '<pre><code>'
  const symbolEnd = '</code></pre>'
  let endPlus = symbolStart.length
  let valueArr = value?.split('')

  if (startPos !== endPos) {
    valueArr.splice(startPos, 0, `${symbolStart}\n`)
    valueArr.splice(endPos + 1, 0, `\n${symbolEnd}\n`)
    endPlus += symbolEnd.length + 3 // 3 - символа переноса строк
  } else {
    valueArr.splice(startPos, 0, `${symbolStart}\n \n`)
    valueArr.splice(startPos + 1, 0, `${symbolEnd}`)
    endPlus += 2
  }

  area.focus()
  cursorPast({
    area,
    startPos,
    endPos,
    endPlus,
  })

  return {
    newValue: valueArr.join(''),
  }
}
