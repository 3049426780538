import { getDay } from 'date-fns'
import ru from 'date-fns/locale/ru'
import DatePicker, { registerLocale } from 'react-datepicker'
import Input from 'ui/components/forms/Input/'

registerLocale('ru', ru)

const DatepickerInput = (props) => {
  const { disableWeekends, selected, onChange } = props

  const isWeekday = (date) => {
    if (disableWeekends) {
      const day = getDay(date)
      return day !== 0 && day !== 6
    }

    return true
  }

  return (
    <DatePicker
      locale="ru"
      dateFormat="dd.MM.yyyy"
      selected={selected}
      onChange={onChange}
      filterDate={isWeekday}
      customInput={<Input {...props} />}
      {...props}
    />
  )
}

export default DatepickerInput
