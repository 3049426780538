export const TASK_TYPE = {
  Bug: 1,
  Task: 2,
  Epic: 5,
  Backlog: 7,
}

export const UserRole = {
  Tester: 2,
}

export const ProjectType = {
  Fix: 1,
  Ransom: 2,
  TM: 3,
  BatchSupport: 4
}