import { $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

export default function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.update(() => {
        const rawHTML = $generateHtmlFromNodes(editor, null)
        onChange(rawHTML)
      })
    })
  }, [editor, onChange])

  return <></>
}
