import { getMinutesFromString } from './getMinutesFromString'

export const prepareCommonSelects = (data, withId = false) => {
  if (Array.isArray(data)) {
    return data.map(({ id, slug, name }) => ({
      id: id || slug,
      value: withId ? `#${id} ${name}` : name,
      label: withId ? `#${id} ${name}` : name,
    }))
  } else {
    return {
      id: data?.id || data?.slug,
      value: withId ? `#${data?.id} ${data?.name}` : data?.name,
      label: withId ? `#${data?.id} ${data?.name}` : data?.name,
    }
  }
}

export const prepareRoleList = (role) => {
  return role?.length > 0
    ? role.map(({ id, name }) => {
        return {
          id: id,
          value: name,
          label: name,
        }
      })
    : []
}

export const prepareStuff = (users) => {
  const preparedStuff = []

  users.forEach((item) => {
    const exsistingIndex = preparedStuff.findIndex(
      (user) => user.id === item.id
    )

    if (exsistingIndex >= 0) {
      preparedStuff[exsistingIndex].role.push(item.role)
    } else {
      preparedStuff.push({
        ...item,
        role: [item.role],
      })
    }
  })

  return preparedStuff
}

export const prepareTaskValues = (values) => {
  const prepared = Object.fromEntries(
    Object.entries(values).map((entity) => {
      return entity.map((value) => {
        if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        ) {
          return value.id
        } else {
          return value
        }
      })
    })
  )
  prepared.estimate_worker = getMinutesFromString(prepared.estimate_worker)

  if (prepared.estimate_cost === '') {
    delete prepared.estimate_cost
    return prepared
  } else {
    prepared.estimate_cost = getMinutesFromString(prepared.estimate_cost)
    return prepared
  }
}

export const prepareUserIdList = (users) => {
  return users?.length > 0 ? users.map(({ id }) => id) : []
}

export const prepareUserList = (users) => {
  return users?.length > 0
    ? users.map(({ id, name, surname }) => {
        return {
          id: id,
          value: `${surname} ${name}`,
          label: `${surname} ${name} `,
        }
      })
    : []
}

export const prepareCurrentUserRoles = (staff, id, roleList) => {
  return staff
    .find((employee) => employee.id === id)
    .role.map((item) => {
      return roleList.find(({ id }) => id === item)
    })
    .reduce((previousValue, currentValue) => {
      previousValue.push(currentValue?.name)
      return previousValue
    }, [])
    .join(' ')
}
