import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDateRange, getResultTable, getTable } from 'api/weeklyPlan'
import { notifyError } from 'ui/components/Notify'

export const fetchResultTable = createAsyncThunk(
  'weeklyPlan/getResultTable',
  async (filters = null) => {
    return await getResultTable(filters)
  }
)

export const fetchTable = createAsyncThunk(
  'weeklyPlan/getTable',
  async (filters = {}) => {
    return await getTable(filters)
  }
)

export const fetchDateRange = createAsyncThunk(
  'weeklyPlan/getDateRange',
  async () => {
    return await getDateRange()
  }
)

const weeklyPlanSlice = createSlice({
  name: 'weeklyPlan',
  initialState: {
    result: [],
    table: [],
    weeksRange: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResultTable.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          state.result = action.payload.data || []
        }
      })
      .addCase(fetchTable.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          state.table = action.payload.data || []
        }
      })
      .addCase(fetchDateRange.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          notifyError(
            errors
              ? Object.values(errors).join(', ')
              : action.payload?.data?.message
          )
        } else {
          state.weeksRange = action.payload.data || []
        }
      })
  },
})

export const weeklyPlanResultSelector = (state) => state.weeklyPlan.result
export const weeklyPlanTableSelector = (state) => state.weeklyPlan.table
export const weeklyPlanWeeksRangeSelector = (state) =>
  state.weeklyPlan.weeksRange

export const {} = weeklyPlanSlice.actions

export default weeklyPlanSlice.reducer
