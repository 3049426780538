import { addDays, parse } from 'date-fns'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import * as Yup from 'yup'
import { uploadFile } from 'api/file'
import { taskFileAttach } from 'api/tasks'
import { checkIsFieldRequired } from 'helpers/checkIsFieldRequired'
import formatTimeBlur from 'helpers/formatTimeBlur'
import isValidURL from 'helpers/isValidURL'
import {
  prepareCommonSelects,
  prepareTaskValues,
  prepareUserIdList,
  prepareUserList,
} from 'helpers/prepares'
import removeDuplicates from 'helpers/removeDuplicates'
import { setDateValue } from 'helpers/setDateValue'
import { usePrevilegies, usePrevilegiesGlobal } from 'hooks/usePrevilegies'
import { TASK_TYPE, UserRole as USER_ROLE } from 'store/constants'
import {
  getPriorityAction,
  getTaskTypeAction,
  taskTypesSelector,
  prioritiesSelector,
  prioritiesStatusSelector,
  taskTypesStatusSelector,
} from 'store/dictionaries'
import { closeTaskAdd, taskAddModalSelector } from 'store/modals'
import {
  allTasksSelector,
  getAllTasksAction,
  getProjectUsersAction,
  // getTaskListAction,
  projectInfoSelector,
} from 'store/projects'
import {
  createProjectTaskAction,
  addProjectTaskStatusSelector,
  createdProjectTaskSelector,
  clearAddTaskStatus,
} from 'store/tasks'
import { getUserListAction, currentUserSelector } from 'store/users'
import { ScreenSize } from 'styles/constants'
import Button from 'ui/components/buttons/Button/'
import ConfirmModal from 'ui/components/ConfirmModal'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import { EditorWrapper } from 'ui/components/forms/EditorWrapper/'
import FileUploader from 'ui/components/forms/FileUploader'
import Input from 'ui/components/forms/Input/'
import Select from 'ui/components/forms/Select/'
import SelectWithCheckboxes from 'ui/components/forms/SelectWithCheckboxes'
import ModalBasic from 'ui/components/ModalBasic/'
import { notifySuccess } from 'ui/components/Notify'
import {
  FieldGroup,
  Buttons,
  SelectWithCheckboxesWrap,
  UploaderContainer,
  NotificationLink,
  DateItem,
  DateGroup,
  Header,
} from './styled'

const TaskAdd = ({}) => {
  const dispatch = useDispatch()
  const {
    defaultType,
    open,
    defaultEpicBy,
    updateRequest,
    openTask,
    defaultRelatedBy,
    defaultTaskDetails,
  } = useSelector(taskAddModalSelector)

  const handlerClose = () => {
    dispatch(closeTaskAdd())
  }

  const router = useRouter()
  const { projectSlug } = router.query
  const { isManager } = usePrevilegies()
  const { isManagerGlobal } = usePrevilegiesGlobal()
  const isMobile = useMedia(ScreenSize.mobile)

  const [epicBy, setEpicBy] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (open) {
      dispatch(getAllTasksAction(projectSlug))
    }
  }, [open])

  const taskList = useSelector(allTasksSelector)
  const epicsList = useSelector(allTasksSelector)?.filter(
    (i) => i.task_type === TASK_TYPE.Epic
  )
  const priorities = useSelector(prioritiesSelector)
  const prioritiesStatus = useSelector(prioritiesStatusSelector)
  const taskTypes = useSelector(taskTypesSelector)
  const taskTypesStatus = useSelector(taskTypesStatusSelector)
  const projectInfo = useSelector(projectInfoSelector)
  const currentUser = useSelector(currentUserSelector)
  const addProjectTaskStatus = useSelector(addProjectTaskStatusSelector)
  const addTaskStatus = useSelector(addProjectTaskStatusSelector)
  const createdProjectTask = useSelector(createdProjectTaskSelector)

  const [files, setFiles] = useState([])
  const [requiredFields, setRequiredFields] = useState({})
  const [visibleTaskFields, setVisibleTaskFields] = useState()
  const [confirmOpened, setConfirmOpened] = useState(false)
  const componentsList = useMemo(() => {
    return projectInfo
      ? prepareCommonSelects(projectInfo.flow.possibleProjectComponents)
      : []
  }, [projectInfo])
  const priorityList = prepareCommonSelects(priorities)
  const taskTypesList = prepareCommonSelects(taskTypes)
  const preparedUsersList = prepareUserList(
    removeDuplicates(projectInfo?.users || [])
  )

  const users = useMemo(() => {
    const canSelectAll = projectInfo?.perm_user_self_assign
      ? !projectInfo?.users
          ?.find((i) => i.id === currentUser?.id)
          ?.role?.some((i) => i.id === USER_ROLE.Tester)
      : false
    if (!isManager && currentUser && canSelectAll) {
      return preparedUsersList?.filter((i) => i.id === currentUser?.id)
    } else {
      return preparedUsersList
    }
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      stage_id: 1,
      task_type_id: defaultType
        ? prepareCommonSelects(taskTypes?.find((i) => i.id === defaultType))
        : { id: 2, value: 'Задача', label: 'Задача' },
      estimate_worker: '',
      estimate_cost: '',
      component_id: undefined,
      priority_id: { id: 2, value: 'Нормальный', label: 'Нормальный' },
      executors: '',
      layout_link: '',
      markup_link: '',
      dev_link: '',
      description: '',
      //svyazi
      block: [],
      related: [],
      release_id: '',
      epic_id: '',
      date_start: null,
      date_end: null,
      // deadline: null,
    },
    validationSchema: Yup.object({
      name: visibleTaskFields?.includes('name')
        ? Yup.string()
            .max(200, 'Название не должно превышать 200 символов')
            .required('Нужно заполнить')
        : Yup.string(),
      task_type_id: visibleTaskFields?.includes('task_type')
        ? Yup.object().required('Нужно заполнить')
        : Yup.object(),
      estimate_worker: visibleTaskFields?.includes('estimate_worker')
        ? Yup.string()
        : Yup.string() || Yup.number(),
      estimate_cost: visibleTaskFields?.includes('estimate_cost')
        ? Yup.string()
        : Yup.string() || Yup.number(),
      executors:
        visibleTaskFields?.includes('users') &&
        checkIsFieldRequired('executors', requiredFields)
          ? Yup.array().min(1).required('Нужно заполнить')
          : Yup.array(),
      files: Yup.array(),
      component_id:
        visibleTaskFields?.includes('component') &&
        checkIsFieldRequired('component_id', requiredFields)
          ? Yup.object().required('Нужно заполнить')
          : Yup.object(),
      priority_id:
        visibleTaskFields?.includes('priority') &&
        checkIsFieldRequired('priority_id', requiredFields)
          ? Yup.object().required('Нужно заполнить')
          : Yup.object(),
      description: checkIsFieldRequired('description', requiredFields)
        ? Yup.string().min(8, 'Нужно заполнить').required('Нужно заполнить')
        : Yup.string().when('task_type_id', {
            is: (task_type_id) =>
              [TASK_TYPE.Epic, TASK_TYPE.Backlog].includes(task_type_id.id),
            then: Yup.string()
              .min(8, 'Нужно заполнить')
              .required('Нужно заполнить'),
          }),
      layout_link: visibleTaskFields?.includes('layout_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      markup_link: visibleTaskFields?.includes('markup_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      dev_link: visibleTaskFields?.includes('dev_link')
        ? Yup.string().test('is-url-valid', 'Некорректный URL', (value) => {
            return isValidURL(value)
          })
        : Yup.string(),
      //svyazi
      block: Yup.array(),
      related: Yup.array(),
      release_id: Yup.object(),
      epic_id: Yup.object(),
      date_start: Yup.string().nullable(true).notRequired(),
      date_end: Yup.string().nullable(true).notRequired(),
      deadline: Yup.string().nullable(true).notRequired(),
    }),
    onSubmit: (values) => {
      const preparedValues = prepareTaskValues(values)
      const executorsIdList = prepareUserIdList(preparedValues.executors)
      const blockIds = values.block?.map((i) => i.id)
      const relatedIds = values.related?.map((i) => i.id)
      setPending(true)
      dispatch(
        createProjectTaskAction({
          slug: projectInfo.slug,
          values:
            values.task_type_id.id === TASK_TYPE.Epic
              ? {
                  ...preparedValues,
                  executors: [],
                  component_id: undefined,
                  estimate_cost: undefined,
                  estimate_worker: undefined,
                  block: undefined,
                  related: undefined,
                  dev_link: undefined,
                  markup_link: undefined,
                  layout_link: undefined,
                }
              : {
                  ...preparedValues,
                  executors: executorsIdList || [],
                  block: blockIds,
                  related: relatedIds,
                },
        })
      )

      setRequiredFields({})
    },
  })

  useEffect(() => {
    formik.setFieldValue(
      'task_type_id',
      defaultType
        ? prepareCommonSelects(taskTypes?.find((i) => i.id === defaultType))
        : { id: 2, value: 'Задача', label: 'Задача' }
    )
  }, [taskTypes, defaultType])

  useEffect(() => {
    setVisibleTaskFields(
      projectInfo?.flow?.task_type_fields_map?.find(
        (i) => i.task_type_id === formik?.values.task_type_id.id
      )?.fields
    )
    handleChangeTaskType(formik?.values.task_type_id?.id || '')
  }, [formik?.values.task_type_id, projectInfo])

  useEffect(() => {
    if (epicsList?.length > 0) {
      setEpicBy(epicsList?.find((i) => i.id === defaultEpicBy))
    }
  }, [defaultEpicBy, epicsList])

  //Обработка предзаполненных полей (при копировании)
  useEffect(() => {
    if (!defaultTaskDetails) {
      return
    }

    let values = {}
    Object.keys(defaultTaskDetails).forEach((key) => {
      if (
        ![
          'name',
          'estimate_worker',
          'estimate_cost',
          'layout_link',
          'markup_link',
          'dev_link',
          'description',
          'date_start',
          'date_end',
        ].includes(key)
      ) {
        return
      }

      values[key] = defaultTaskDetails[key] || ''
    })

    values['priority_id'] = priorityList.find(
      (i) => i.id === defaultTaskDetails.priority?.id
    )
    values['component_id'] = componentsList.find(
      (i) => i.id === defaultTaskDetails.component?.id
    )

    formik.setFieldValue(
      'component_id',
      componentsList.find((i) => i.id === defaultTaskDetails.component?.id)
    )

    if (defaultTaskDetails.users) {
      const userIds = new Set(defaultTaskDetails.users.map(({ id }) => id))
      values['executors'] = users.filter((i) => userIds.has(i.id))
    }

    if (defaultTaskDetails.release) {
      const release = taskList.find(
        (i) => defaultTaskDetails.release.id === i.id
      )
      values['release_id'] = prepareCommonSelects(release)
    }

    if (defaultTaskDetails.block) {
      const blockIds = new Set(defaultTaskDetails.block.map(({ id }) => id))
      const blockList = taskList.filter((i) => blockIds.has(i.id))
      values['block'] = prepareCommonSelects(blockList, true)
    }

    values['task_type_id'] = defaultType
      ? prepareCommonSelects(taskTypes?.find((i) => i.id === defaultType))
      : { id: 2, value: 'Задача', label: 'Задача' }

    formik.setValues(
      {
        ...formik.values,
        ...values,
      },
      true
    )
  }, [defaultTaskDetails])

  useEffect(() => {
    if (epicBy) {
      formik.setFieldValue('epic_id', prepareCommonSelects(epicBy, true))
    }
  }, [epicBy])

  useEffect(() => {
    if (defaultRelatedBy) {
      formik.setFieldValue(
        'related',
        prepareCommonSelects(
          taskList?.filter((i) => i.id === defaultRelatedBy),
          true
        )
      )
    }
  }, [defaultRelatedBy, taskList])

  // useEffect(() => {
  //   if (formik.block) {
  //     dispatch(getTaskListAction({ slug: projectSlug, task: formik.block }))
  //   }
  // }, [formik])

  useEffect(() => {
    if (addProjectTaskStatus === 'success') {
      formik.resetForm()
      setFiles([])
    }
  }, [addProjectTaskStatus])

  useEffect(() => {
    if (open) {
      dispatch(getUserListAction())
      if (priorities.length === 0 && prioritiesStatus !== 'pending') {
        dispatch(getPriorityAction())
      }
      if (taskTypes.length === 0 && taskTypesStatus !== 'pending') {
        dispatch(getTaskTypeAction())
      }
    }
  }, [open])

  const sendFileHandler = (fileList, updateFiles) => {
    const fd = new FormData()
    fileList.forEach((file) => {
      fd.append('file[]', file)
    })
    uploadFile(fd).then(({ data }) => {
      if (data) {
        setFiles([...files, ...data])
        updateFiles(data)
      }
    })
  }

  const deleteFileHandler = (fileId) => {
    setFiles(files.filter((file) => file.id !== fileId))
  }

  useEffect(async () => {
    if (addTaskStatus === 'success') {
      ;(async () => {
        if (files?.length) {
          files?.forEach(async (file) => {
            await taskFileAttach(createdProjectTask.data.id, file.id)
          })
        }
        updateRequest && (await updateRequest())
      })()
      setPending(false)
      dispatch(clearAddTaskStatus())
      handlerClose()
      if (createdProjectTask) {
        dispatch(getProjectUsersAction(projectSlug))
        notifySuccess(
          <a
            href={`${window.location.origin}/projects/${projectSlug}/${createdProjectTask.data.id}`}
            onClick={(e) => {
              openTask(e, createdProjectTask.data.id)
            }}
          >
            Задача
            <NotificationLink>
              {` #${createdProjectTask.data.id} `}
            </NotificationLink>
            успешно добавлена
            <div>
              <NotificationLink>Открыть</NotificationLink>
            </div>
          </a>
        )
      }
    }
    if (addTaskStatus === 'failed') {
      setPending(false)
    }
  }, [addTaskStatus])

  const closeModal = () => {
    setConfirmOpened(false)
    formik.resetForm()
    setRequiredFields({})
    setFiles([])
    handlerClose()
  }

  const handleChangeTaskType = (taskTypeId) => {
    switch (taskTypeId) {
      // Эпик
      case TASK_TYPE.Epic:
        setRequiredFields({
          description: false,
        })
        break
      // Бэклог
      case TASK_TYPE.Backlog:
        setRequiredFields({
          component_id: false,
          description: false,
        })
        break
      default:
        setRequiredFields({})
        break
    }
  }

  const startDateHasValue = Boolean(formik.getFieldMeta('date_start').value)

  useEffect(() => {
    if (!startDateHasValue) {
      formik.setFieldValue('date_end', null)
    }
  }, [startDateHasValue])

  return (
    <>
      <ModalBasic
        size={isMobile ? 'fullscreen' : 'large'}
        open={open}
        handlerClose={() => setConfirmOpened(true)}
      >
        <Header>
          <h2>Создание задачи</h2>
        </Header>
        <div>
          <ConfirmModal
            title="Закрыть окно?"
            open={confirmOpened}
            confirmHandler={closeModal}
            handlerClose={() => setConfirmOpened(false)}
          />
          <form onSubmit={formik.handleSubmit} noValidate id="taskAdd">
            {visibleTaskFields?.includes('name') && (
              <Input
                required={true}
                id="addNameSelect"
                label="Название"
                name="name"
                meta={formik.getFieldMeta('name')}
                {...formik.getFieldProps('name')}
              />
            )}
            <FieldGroup count={3}>
              <Select
                id="addTypeSelect"
                required={true}
                options={taskTypesList}
                value={
                  formik.getFieldMeta('task_type_id')
                    ? formik.getFieldMeta('task_type_id').value
                    : null
                }
                meta={formik.getFieldMeta('task_type_id')}
                error={
                  formik.getFieldMeta('task_type_id').touched &&
                  formik.getFieldMeta('task_type_id').error
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('task_type_id', newValue)
                  } else {
                    formik.setFieldValue('task_type_id', '')
                  }
                }}
                label="Тип задачи"
                defaultTitle="Любой"
                maxHeight="174px"
              />
              {visibleTaskFields?.includes('component') && (
                <Select
                  isClearable={true}
                  required={checkIsFieldRequired(
                    'component_id',
                    requiredFields
                  )}
                  id="addComponentSelect"
                  options={componentsList}
                  value={
                    formik.getFieldMeta('component_id')
                      ? formik.getFieldMeta('component_id').value
                      : null
                  }
                  meta={formik.getFieldMeta('component_id')}
                  error={
                    formik.getFieldMeta('component_id').touched &&
                    formik.getFieldMeta('component_id').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('component_id', newValue)
                    } else {
                      formik.setFieldValue('component_id', '')
                    }
                  }}
                  label="Компонент"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
              {visibleTaskFields?.includes('users') && (
                <SelectWithCheckboxesWrap>
                  <SelectWithCheckboxes
                    isClearable={true}
                    required={checkIsFieldRequired('executors', requiredFields)}
                    id="addExecutorsSelect"
                    options={users}
                    value={
                      formik.getFieldMeta('executors')
                        ? formik.getFieldMeta('executors').value
                        : null
                    }
                    meta={formik.getFieldMeta('executors')}
                    error={
                      formik.getFieldMeta('executors').touched &&
                      formik.getFieldMeta('executors').error
                    }
                    onChange={(newValue) => {
                      if (newValue) {
                        formik.setFieldValue('executors', newValue)
                      } else {
                        formik.setFieldValue('executors', '')
                      }
                    }}
                    label="Исполнитель"
                    defaultTitle="Любой"
                    maxHeight="174px"
                  />
                </SelectWithCheckboxesWrap>
              )}
              {visibleTaskFields?.includes('priority') && (
                <Select
                  id="addPrioritySelect"
                  isClearable={true}
                  required={checkIsFieldRequired('priority_id', requiredFields)}
                  options={priorityList}
                  value={
                    formik.getFieldMeta('priority_id')
                      ? formik.getFieldMeta('priority_id').value
                      : null
                  }
                  meta={formik.getFieldMeta('priority_id')}
                  error={
                    formik.getFieldMeta('priority_id').touched &&
                    formik.getFieldMeta('priority_id').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('priority_id', newValue)
                    } else {
                      formik.setFieldValue('priority_id', '')
                    }
                  }}
                  label="Приоритет"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
              {visibleTaskFields?.includes('estimate_worker') && (
                <Input
                  id="addEstimateWorkerInput"
                  label="Оценка"
                  name="estimate_worker"
                  meta={formik.getFieldMeta('estimate_worker')}
                  {...formik.getFieldProps('estimate_worker')}
                  onBlur={(event) =>
                    formatTimeBlur(event, formik, 'estimate_worker')
                  }
                />
              )}
              {visibleTaskFields?.includes('estimate_cost') && (
                <Input
                  label="Оценка по смете"
                  id="addEstimateCostInput"
                  name="estimate_cost"
                  meta={formik.getFieldMeta('estimate_cost')}
                  {...formik.getFieldProps('estimate_cost')}
                  onBlur={(event) =>
                    formatTimeBlur(event, formik, 'estimate_cost')
                  }
                />
              )}
            </FieldGroup>

            <DateGroup>
              <DateItem>
                <DatepickerInput
                  id="dateStartPicker"
                  autoComplete="off"
                  label="Дата начала"
                  isClearable
                  meta={formik.getFieldMeta('date_start')}
                  selected={
                    formik.getFieldMeta('date_start').value
                      ? parse(
                          formik.getFieldMeta('date_start').value,
                          'dd.MM.yyyy',
                          new Date()
                        )
                      : null
                  }
                  onChange={(date) => setDateValue(formik, 'date_start', date)}
                  instanceId="datepicker"
                />
              </DateItem>
              <DateItem>
                <DatepickerInput
                  id="dateEndPicker"
                  autoComplete="off"
                  label="Дата завершения"
                  disabled={!startDateHasValue}
                  isClearable
                  minDate={
                    formik.getFieldMeta('date_start').value
                      ? addDays(
                          parse(
                            formik.getFieldMeta('date_start').value,
                            'dd.MM.yyyy',
                            new Date()
                          ),
                          1
                        )
                      : null
                  }
                  meta={formik.getFieldMeta('date_end')}
                  selected={
                    formik.getFieldMeta('date_end').value
                      ? parse(
                          formik.getFieldMeta('date_end').value,
                          'dd.MM.yyyy',
                          new Date()
                        )
                      : null
                  }
                  onChange={(date) => setDateValue(formik, 'date_end', date)}
                  instanceId="datepicker"
                />
              </DateItem>
              {(isManagerGlobal || isManager) && (
                <DateItem>
                  <DatepickerInput
                    id="timePicker"
                    autoComplete="off"
                    label="Дедлайн"
                    meta={formik.getFieldMeta('deadline')}
                    isClearable
                    disabled={!(isManagerGlobal || isManager)}
                    selected={
                      formik.getFieldMeta('deadline').value
                        ? parse(
                            formik.getFieldMeta('deadline').value,
                            'dd.MM.yyyy',
                            new Date()
                          )
                        : null
                    }
                    onChange={(date) => setDateValue(formik, 'deadline', date)}
                    instanceId="datepicker"
                  />
                </DateItem>
              )}
            </DateGroup>
            {visibleTaskFields?.includes('description') && (
              <EditorWrapper
                editorVersion={2}
                required={checkIsFieldRequired('description', requiredFields)}
                label="Описание"
                name="description"
                meta={formik.getFieldMeta('description')}
                value={formik.getFieldProps('description').value}
                onChange={(value) => {
                  formik.setFieldValue('description', value)
                }}
              />
            )}
            {visibleTaskFields?.includes('can_attach_file') && (
              <UploaderContainer>
                <FileUploader
                  id="descriptionFile"
                  items={files}
                  smallPreview={true}
                  showUploader={true}
                  deleteFileHandler={deleteFileHandler}
                  sendFileHandler={sendFileHandler}
                />
              </UploaderContainer>
            )}
            <FieldGroup count={4}>
              {visibleTaskFields?.includes('block') && (
                <SelectWithCheckboxes
                  id="addBlockSelect"
                  isClearable={true}
                  options={taskList?.map((i) => {
                    return {
                      id: i.id,
                      value: `#${i.id} ${i.name}`,
                      label: `#${i.id} ${i.name}`,
                    }
                  })}
                  meta={formik.getFieldMeta('block')}
                  error={
                    formik.getFieldMeta('block').touched &&
                    formik.getFieldMeta('block').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('block', newValue)
                    } else {
                      formik.setFieldValue('block', '')
                    }
                  }}
                  value={
                    formik.getFieldMeta('block')
                      ? formik.getFieldMeta('block').value
                      : null
                  }
                  label="Блокирует задачу"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
              {visibleTaskFields?.includes('related') && (
                <SelectWithCheckboxes
                  id="addRelateSelect"
                  isClearable={true}
                  options={taskList?.map((i) => {
                    return {
                      id: i.id,
                      value: `#${i.id} ${i.name}`,
                      label: `#${i.id} ${i.name}`,
                    }
                  })}
                  value={
                    formik.getFieldMeta('related')
                      ? formik.getFieldMeta('related').value
                      : null
                  }
                  meta={formik.getFieldMeta('related')}
                  error={
                    formik.getFieldMeta('related').touched &&
                    formik.getFieldMeta('related').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('related', newValue)
                    } else {
                      formik.setFieldValue('related', '')
                    }
                  }}
                  label="Связана с"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
              {visibleTaskFields?.includes('release') && (
                <Select
                  id="addReleaseSelect"
                  isClearable={true}
                  options={taskList?.map((i) => {
                    return {
                      id: i.id,
                      value: `#${i.id} ${i.name}`,
                      label: `#${i.id} ${i.name}`,
                    }
                  })}
                  value={
                    formik.getFieldMeta('release_id')
                      ? formik.getFieldMeta('release_id').value
                      : null
                  }
                  meta={formik.getFieldMeta('release_id')}
                  error={
                    formik.getFieldMeta('release_id').touched &&
                    formik.getFieldMeta('release_id').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('release_id', newValue)
                    } else {
                      formik.setFieldValue('release_id', '')
                    }
                  }}
                  label="В релизе"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
              {visibleTaskFields?.includes('epic') && (
                <Select
                  id="addEpicSelect"
                  isClearable={true}
                  options={epicsList?.map((i) => {
                    return {
                      id: i.id,
                      value: `#${i.id} ${i.name}`,
                      label: `#${i.id} ${i.name}`,
                    }
                  })}
                  value={
                    formik.getFieldMeta('epic_id')
                      ? formik.getFieldMeta('epic_id').value
                      : null
                  }
                  meta={formik.getFieldMeta('epic_id')}
                  error={
                    formik.getFieldMeta('epic_id').touched &&
                    formik.getFieldMeta('epic_id').error
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      formik.setFieldValue('epic_id', newValue)
                    } else {
                      formik.setFieldValue('epic_id', '')
                    }
                  }}
                  label="Относится к эпику"
                  defaultTitle="Любой"
                  maxHeight="174px"
                />
              )}
            </FieldGroup>
            <FieldGroup count={3}>
              {visibleTaskFields?.includes('layout_link') && (
                <Input
                  id="addLayoutLinkInput"
                  label="Layout Link"
                  name="layout_link"
                  meta={formik.getFieldMeta('layout_link')}
                  {...formik.getFieldProps('layout_link')}
                />
              )}
              {visibleTaskFields?.includes('markup_link') && (
                <Input
                  id="addMarkupLinkInput"
                  label="Markup Link"
                  name="markup_link"
                  meta={formik.getFieldMeta('markup_link')}
                  {...formik.getFieldProps('markup_link')}
                />
              )}
              {visibleTaskFields?.includes('dev_link') && (
                <Input
                  id="addDevLinkInput"
                  label="Dev Link"
                  name="dev_link"
                  meta={formik.getFieldMeta('dev_link')}
                  {...formik.getFieldProps('dev_link')}
                />
              )}
            </FieldGroup>
          </form>
          <Buttons>
            <Button
              title="Создать задачу"
              onClick={formik.handleSubmit}
              loading={pending}
              type="submit"
              form="taskAdd"
            />
            <Button
              title="Отменить"
              theme="borderBlue"
              type="reset"
              onClick={closeModal}
            />
          </Buttons>
        </div>
      </ModalBasic>
    </>
  )
}

export default TaskAdd
