import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const openUserRoleModalAction = createAsyncThunk(
  'modals/openUserRoleModal',
  (data) => {
    return data
  }
)

export const openTaskAddModalAction = createAsyncThunk(
  'modals/openTaskAddModal',
  (data) => {
    return data
  }
)

export const openTaskEditModalAction = createAsyncThunk(
  'modals/openTaskEditModal',
  (data) => {
    return data
  }
)
export const openTagModalAction = createAsyncThunk(
  'modals/openTagModal',
  (data) => {
    return data
  }
)

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    userRole: null,
    taskAdd: {
      defaultType: null,
      defaultEpicBy: null,
      defaultRelatedBy: null,
      open: false,
      defaultTaskDetails: null,
    },
    taskEdit: {
      defaultType: null,
      defaultEpicBy: null,
      open: false,
      title: 'Редактирование задачи',
    },
    tag: {
      open: false,
      title: 'Теги',
    },
  },
  reducers: {
    closeUserRoleModal: (state) => {
      state.userRole = null
    },
    closeTaskAdd: (state) => {
      state.taskAdd = {
        open: false,
        defaultType: null,
        defaultEpicBy: null,
        defaultRelatedBy: null,
        openTask: null,
      }
    },
    closeTaskEdit: (state) => {
      state.taskEdit = {
        open: false,
        updateRequest: null,
      }
    },
    closeTagModal: (state) => {
      state.tag = {
        open: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openUserRoleModalAction.fulfilled, (state, action) => {
      state.userRole = {
        open: true,
        title: 'Сотрудники на проекте',
        projectSlug: action.payload.projectSlug,
      }
    })
    builder.addCase(openTaskAddModalAction.fulfilled, (state, action) => {
      state.taskAdd = {
        open: true,
        defaultTaskDetails: action?.payload?.defaultTaskDetails,
        defaultType: action.payload.defaultType,
        defaultEpicBy: action.payload.defaultEpicBy,
        updateRequest: action.payload.updateRequest,
        openTask: action.payload.openTask,
        defaultRelatedBy: action.payload.defaultRelatedBy,
      }
    })
    builder.addCase(openTaskEditModalAction.fulfilled, (state, action) => {
      state.taskEdit = {
        open: true,
        defaultType: action.payload.defaultType,
        defaultEpicBy: action.payload.defaultEpicBy,
        updateRequest: action.payload.updateRequest,
        openTask: action.payload.openTask,
      }
    })
    builder.addCase(openTagModalAction.fulfilled, (state) => {
      state.tag = {
        open: true,
      }
    })
  },
})

export const { closeUserRoleModal } = modalsSlice.actions
export const { closeTaskAdd } = modalsSlice.actions
export const { closeTaskEdit } = modalsSlice.actions
export const { closeTagModal } = modalsSlice.actions

export const taskEditModalSelector = (state) => state.modals.taskEdit
export const taskAddModalSelector = (state) => state.modals.taskAdd
export const userRoleModalSelector = (state) => state.modals.userRole
export const tagModalSelector = (state) => state.modals.tag

export default modalsSlice.reducer
