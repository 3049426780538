import { sortTasksByLexorank } from 'helpers/orderTasksByLexorank'
import { api } from '../api'
import { getTaskListParams } from './utils'

export const tasksApi = api
  .enhanceEndpoints({
    addTagTypes: ['KanbanTaskList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getKanbanTaskList: builder.query({
        keepUnusedDataFor: 5,
        providesTags: ['KanbanTaskList'],
        query: ({ slug, params }) => {
          const preparedParams = getTaskListParams(params)
          return {
            url: `/project/${slug}/task`,
            params: preparedParams,
          }
        },
        transformResponse: (response) => {
          const tasks = response?.data

          if (tasks.length) {
            return tasks.sort(sortTasksByLexorank)
          }

          return []
        },
      }),
    }),
  })

export const { useGetKanbanTaskListQuery } = tasksApi
