import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getEstimatesList,
  getEstimateDetail,
  createEstimate,
  updateEstimate,
} from 'api/estimates/'

export const getEstimatesListAction = createAsyncThunk(
  'estimates/getEstimatesList',
  async (projectSlug) => {
    return await getEstimatesList(projectSlug)
  }
)

export const getEstimateDetailAction = createAsyncThunk(
  'estimates/getEstimateDetail',
  async (id) => {
    return await getEstimateDetail(id)
  }
)

export const createEstimateAction = createAsyncThunk(
  'estimates/createEstimate',
  async ({ projectSlug, data }) => {
    return await createEstimate({ projectSlug, data })
  }
)

export const updateEstimateAction = createAsyncThunk(
  'estimates/updateEstimate',
  async ({ id, data }) => {
    return await updateEstimate({ id, data })
  }
)
