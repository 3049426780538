import { newLineSymbol, regNewLineSymbol } from 'helpers/constants/reg'

export const getArea = (id) => {
  const area = document.getElementById(id)
  const startPos = area.selectionStart
  const endPos = area.selectionEnd

  return {
    area,
    startPos,
    endPos
  }
}

export const getStartLineIndex = (value, startPos) => {
  const valueArr = value?.split('')
  const startLineIndex = valueArr.slice(0, startPos)
    .lastIndexOf(newLineSymbol) + 1

  return {
    startLineIndex
  }
}

export const findNewLineSymbol = (value, start, end) => {
  return !!value.slice(start, end)
    .match(regNewLineSymbol)
}

export const cursorPast = ({ area, startPos, endPos, endPlus }) => {
  if (startPos !== endPos) {
    setTimeout(() => {
      area.selectionStart = endPos + endPlus
      area.selectionEnd = endPos + endPlus
    }, 0)
  } else {
    setTimeout(() => {
      area.selectionStart = endPos + endPlus
      area.selectionEnd = endPos + endPlus
    }, 0)
  }
}

export const getSelectedLine = (value, start, end) => {
  const selectedValue = value.slice(start, end)
  const stringsArr = selectedValue.split(/<br \/>/g)[0].split(regNewLineSymbol) // получаем выделенные строки

  return {
    selectedValue,
    stringsArr
  }
}
