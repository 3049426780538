import styled, { css } from 'styled-components'
import { PALETTE } from 'styles/constants'
import { Colors } from 'utils/constants'

export const InputWrap = styled.label`
  position: relative;
  display: block;
  word-break: break-word;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '0')};
  width: ${(p) => (p.half ? '50%' : '100%')};

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.has-error {
    input {
      border-color: ${Colors.Red.LRed};
      background-color: ${Colors.Red.LRed};
    }
  }

  &.has-success {
    input {
      border-color: ${PALETTE.green};
    }
  }

  & .wmde-markdown {
    font-family: inherit;
    font-size: 14px;
    padding-right: 15px;
  }
`

export const InputLabel = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: ${PALETTE.grey};

  span {
    color: ${PALETTE.popPink};
  }
`

export const InputInner = styled.div`
  position: relative;

  /* svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  } */

  .errorIcon,
  .successIcon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }
`

export const InputElement = styled.input`
  width: 100%;
  height: 42px;
  padding: 0 25px 0 19px;
  border: 1px solid ${Colors.Light.CardsBack};
  background-color: ${Colors.Light.CardsBack};
  border-radius: 8px;
  transition: all 0.3s;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${Colors.Black.BlocksBackground};

  &::placeholder {
    color: ${Colors.Black.TextSec};
    opacity: 1;
  }

  &:not(:focus):hover {
    border-color: ${Colors.Black.BlueGrey};
  }

  &:focus {
    border-color: ${Colors.DS.MainBlue};
    outline: none;
  }

  &:disabled {
    background-color: ${PALETTE.whiteSmoke};
    border-color: ${PALETTE.lightGrey};
    color: ${PALETTE.grey};
    pointer-events: none;
  }

  ${(p) =>
    p.withIcon &&
    css`
      padding-left: 46px;
    `}/* ${(p) =>
    p.themeSize === 'middle' &&
    css`
      height: 48px;
    `} */
`

export const InputIcon = styled.div`
  width: 46px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputError = styled.div`
  color: ${PALETTE.popPink};
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 5px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -6px;
  display: flex;
  align-items: center;
  width: 10px;
  height: 10px;
  z-index: 8;
  cursor: pointer;
`
