import getConfig from 'next/config'
import axios from 'axios'
import { removeCookie, getCookiesValue } from 'helpers/cookie'

export const {
  publicRuntimeConfig: { baseURL },
} = getConfig()

const instance = axios.create({
  baseURL,
})

const requestMiddleware = async (config) => {
  const accessToken = getCookiesValue('ac_token')
  const id = window?.Echo?.socketId?.()
  return {
    ...config,
    ...(accessToken && {
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
        ...(id && { ['X-Socket-Id']: id }),
      },
    }),
  }
}

instance.interceptors.request.use(requestMiddleware)

instance.interceptors.response.use(
  function (response) {
    return response?.data
  },
  function (error) {
    if (error?.response?.status === 401) {
      removeCookie('ac_token')
    }
    return Promise.reject(error)
  }
)

export default instance
