import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { projectInfoSelector } from 'store/projects'
import { currentUserSelector } from 'store/users'
import Icon from 'ui/components/Icon'
import { ProjectEditModal } from 'ui/modules/Projects/components/ProjectEdit'
import { useMenu } from './hooks'
import {
  Wrapper,
  List,
  Elem,
  ControlIcon,
  ControlTitle,
  ProjectMenu,
  ProjectMenuBox,
  ProjectMenuList,
  ProjectMenuTitle,
  ProjectLogo,
  ProjectName,
} from './styled'

export const Menu = ({ setMenuOpened, theme }) => {
  const router = useRouter()
  const { generalMenu, projectMenu, editProjectOpened, setEditProjectOpened } =
    useMenu()
  const { isManager } = usePrevilegies()
  const currentUser = useSelector(currentUserSelector)
  const projectInfo = useSelector(projectInfoSelector)

  const checkIsActive = (href) => router.pathname === href

  const navClick = (event, url, query) => {
    event.preventDefault()
    query?.project
      ? router.push({
          pathname: url,
          query: {
            project: query.project,
            group: query.group,
            hideProjectSelect: 1,
            hideUserSelect: isManager ? 0 : 1,
            getReport: 1,
            user: isManager ? '' : currentUser?.id,
          },
          url,
        })
      : router.push(url)
    setMenuOpened(false)
  }

  const visibleProjetMenu = !!projectInfo

  const renderMenu = (items) =>
    items.map((item, index) => (
      <Elem
        key={index}
        title={item.title}
        href={item.href}
        isActive={checkIsActive(item.asHref)}
        aria-selected={checkIsActive(item.asHref)}
        theme={theme}
        onClick={item?.onClick ? item?.onClick : (e) => navClick(e, item.href)}
      >
        <ControlIcon
          color={theme === 'light' && item?.iconColor ? item.iconColor : null}
        >
          <Icon name={item.icon} />
        </ControlIcon>
        <ControlTitle>{item.title}</ControlTitle>
      </Elem>
    ))

  const renderProjectMenu = useMemo(
    () => renderMenu(projectMenu),
    [projectMenu, router.pathname, theme]
  )
  const renderGeneralMenu = useMemo(
    () => renderMenu(generalMenu),
    [generalMenu, router.pathname, theme]
  )

  return (
    <>
      {editProjectOpened && (
        <ProjectEditModal
          headerTitle="Редактирование проекта"
          isOpened={true}
          onCloseHandler={() => setEditProjectOpened(false)}
          data={projectInfo}
        />
      )}
      <Wrapper>
        {visibleProjetMenu && (
          <ProjectMenu theme={theme}>
            <ProjectMenuBox>
              <Link href={`/projects/${projectInfo?.slug}`}>
                <a>
                  <ProjectMenuTitle>
                    <ProjectLogo>
                      <img src={projectInfo?.logo?.link} />
                    </ProjectLogo>
                    <ProjectName>{projectInfo?.name}</ProjectName>
                  </ProjectMenuTitle>
                </a>
              </Link>
              <ProjectMenuList>{renderProjectMenu}</ProjectMenuList>
            </ProjectMenuBox>
          </ProjectMenu>
        )}
        <List>{renderGeneralMenu}</List>
      </Wrapper>
    </>
  )
}
