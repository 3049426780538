/* eslint-disable @next/next/no-img-element */
import { LogoRoot, LogoIcon, LogoTitle } from './styled'

export const Logo = ({ dark }) => (
  <LogoRoot>
    <LogoIcon>
      {dark ? (
        <img src="/images/icons/DSLogoDark.svg" />
      ) : (
        <img src="/images/icons/DSLogo.svg" />
      )}
    </LogoIcon>
    <LogoTitle dark={dark}>DS АКАДЕМИЯ</LogoTitle>
  </LogoRoot>
)
