import { useDispatch } from 'react-redux'
import { useDeleteProjectUserMutation } from 'apiv2'
import { groupRoleByUserId } from 'helpers/groupRoleByUserId'
import { openUserRoleModalAction } from 'store/modals'
import { getProjectInfoAction } from 'store/projects'
import { InputLabel } from 'ui/components//forms/Input/styled'
import Button from 'ui/components/buttons/Button'
import ControlButton from 'ui/components/ControlButton/'
import UserCardSmall from 'ui/components/UserCardSmall/'
import {
  Wrapper,
  UserList,
  UserElem,
  UserCard,
  UserDelete,
  ControlIcon,
} from './styled'
import Icon from '../Icon'
import ModalUserRole from '../ModalUserRole'

const UserRoleWidget = ({ onCloseHandler, users, projectSlug }) => {
  const dispatch = useDispatch()
  const [userProjectDelete] = useDeleteProjectUserMutation()

  const openUserRoleModal = (projectSlug) => {
    dispatch(openUserRoleModalAction({ projectSlug }))
  }

  const handleDeletion = async (user) => {
    for (const role of user.role) {
      await userProjectDelete({
        slug: projectSlug,
        userId: user.id,
        roleId: role,
      })
    }

    await dispatch(getProjectInfoAction(projectSlug))
  }

  if (!users) {
    return null
  }

  const groupedUsers = groupRoleByUserId(users)

  return (
    <Wrapper>
      <InputLabel>Пользователи на проекте</InputLabel>
      <UserList>
        {groupedUsers.map((user) => {
          return (
            <UserElem key={user.id}>
              <UserCard>
                <UserCardSmall
                  handler={onCloseHandler}
                  href={`/users/${user.id}`}
                  name={`${user.surname} ${user.name}`}
                  additionalText={user.role?.map((i) => {
                    return i.name
                  })}
                  avatar={user.avatar?.link}
                />
              </UserCard>
              <UserDelete>
                <ControlButton
                  theme="grey"
                  onClick={(e) => {
                    e.preventDefault()
                    handleDeletion(user)
                  }}
                >
                  <ControlIcon>
                    <Icon name="close" />
                  </ControlIcon>
                </ControlButton>
              </UserDelete>
            </UserElem>
          )
        })}
      </UserList>
      <Button
        theme="dashedGrey"
        title="Редактировать"
        onClick={(event) => {
          event.preventDefault()
          openUserRoleModal(projectSlug)
        }}
      />

      <ModalUserRole onClick={() => openUserRoleModal(projectSlug)} />
    </Wrapper>
  )
}

export default UserRoleWidget
