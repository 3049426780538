import styled from 'styled-components'
import { PALETTE } from 'styles/constants'
import { Colors } from 'utils/constants'

export const SelectWrap = styled.div`
  width: 100%;
  position: relative;

  &.has-error {
    .Select__control {
      border-color: ${Colors.Red.LRed};
      background-color: ${Colors.Red.LRed};
    }
  }

  &:not(:last-child) {
    margin-bottom: 28px;
  }

  .errorIcon,
  .successIcon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }
`

export const SelectLabel = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: #828d99;

  span {
    color: ${PALETTE.popPink};
  }
`

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const OptionText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`
export const SelectError = styled.div`
  color: ${PALETTE.popPink};
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 5px;
`
