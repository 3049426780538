import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodes } from 'lexical'
import { useEffect } from 'react'

export default function LoadInitialContent({ initValue }) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initValue) {
      return editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(initValue, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        // $getRoot().select()
        $insertNodes(nodes)
      })
    }
  }, [])

  return <></>
}
