import { SidebarModalLabelTitle, SidebarModalLabelWrap } from './styled'

const SidebarModalLabel = ({ title, children }) => {
  return (
    <SidebarModalLabelWrap>
      {title && <SidebarModalLabelTitle>{title}</SidebarModalLabelTitle>}
      {children}
    </SidebarModalLabelWrap>
  )
}

export default SidebarModalLabel