import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getManagers } from 'api/users/'
import { notifyError } from 'ui/components/Notify'

export const getManagersAction = createAsyncThunk(
  'managers/getList',
  async (data) => {
    return getManagers(data)
  }
)

const managersSlice = createSlice({
  name: 'managers',
  initialState: {
    getListStatus: 'idle',
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getManagersAction.pending, (state) => {
        state.getListStatus = 'pending'
      })
      .addCase(getManagersAction.fulfilled, (state, action) => {
        state.list = action.payload.data || []
        state.getListStatus = 'succeeded'
      })
      .addCase(getManagersAction.rejected, (state) => {
        state.getListStatus = 'failed'
        notifyError('Ошибка получения менеджеров')
      })
  },
})

export const managersListSelector = (state) => state.managers.list
export const managersStatusSelector = (state) => state.managers.getListStatus

export default managersSlice.reducer
