import styled, { css } from 'styled-components'
import { BREAKPOINTS, PALETTE } from 'styles/constants'

export const Control = styled.button`
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  transition: 0.3s ease-out;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 10px;
  }

  ${(p) =>
    p.active &&
    css`
      color: ${PALETTE.popPink};
    }
      &:hover{
        opacity: .6;
      `}

  ${(p) =>
    p.theme === 'base' &&
    css`
      color: ${PALETTE.primary};

      &:hover {
        color: ${PALETTE.lightPrimary};
      }
    `}

  ${(p) =>
    p.theme === 'edit' &&
    css`
      color: ${PALETTE.grey};

      &:hover {
        color: ${PALETTE.primary};
      }
    `}

  ${(p) =>
    p.theme === 'grey' &&
    css`
      color: ${PALETTE.grey};

      &:hover {
        color: ${PALETTE.popPink};
      }
    `}
`
