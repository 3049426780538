import Button from 'ui/components/buttons/Button'
import { ButtonGroup } from 'ui/components/buttons/Button/styled'
import ModalBasic from 'ui/components/ModalBasic/'

const ConfirmModal = ({ title, open, confirmHandler, handlerClose }) => {
  return (
    <ModalBasic open={open} handlerClose={handlerClose} title={title}>
      <ButtonGroup>
        <Button title="Да" onClick={confirmHandler} />
        <Button
          title="Нет"
          theme="borderGrey"
          type="reset"
          onClick={handlerClose}
        />
      </ButtonGroup>
    </ModalBasic>
  )
}

export default ConfirmModal
