export const groupRoleByUserId = (users) => {
  if (!Array.isArray(users)) {
    return null
  }

  const uniqueObjects = {}

  users.forEach((user) => {
    if (uniqueObjects[user.id]) {
      uniqueObjects[user.id].role.push(user.role)
    } else {
      uniqueObjects[user.id] = { ...user, role: [user.role] }
    }
  })

  return Object.values(uniqueObjects).reverse()
}
