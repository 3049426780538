export default (filters = {}) => {
  let str = []
  for (const p in filters) {
    if (filters.hasOwnProperty(p)) {
      str.push(
        'filters[' +
          encodeURIComponent(p) +
          '] = ' +
          encodeURIComponent(filters[p])
      )
    }
  }
  return str.join('&')
}
