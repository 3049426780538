import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'
import { Colors } from '../../../utils'

export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 1000;
`

export const Section = styled.div`
  padding: 32px 32px 5px 32px;
  // todo вернуть когда будет полный редизайн
  //background-color: ${Colors.Light.CardsBack};

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 16px 16px 0;
  }
`

export const Wrapper = styled.div`
  margin-left: -272px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin-left: 0;
  }
`
