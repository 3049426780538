import styled from 'styled-components'
import { PALETTE, BREAKPOINTS } from 'styles/constants'
import { Colors } from 'utils'

export const HeaderBar = styled.div`
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  color: ${PALETTE.white};
  background-color: ${Colors.Black.TextBackground};

  @media (min-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

export const HeaderBurger = styled.button`
  cursor: pointer;
  width: 48px;
  height: 48px;
  color: ${PALETTE.white};
  display: flex;
  align-items: center;
  justify-content: center;
`
