import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchGradesList } from 'api/grades'
import { notifyError } from 'ui/components/Notify'

export const getGradeList = createAsyncThunk('grades/getList', async (id) => {
  return await fetchGradesList(id)
})

const gradesSlice = createSlice({
  name: 'grades',
  initialState: {
    list: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getGradeList.fulfilled, (state, action) => {
      if (action.payload?.errorMessage) {
        const errors = action.payload?.data?.errors || null
        notifyError(
          errors
            ? Object.values(errors).join(', ')
            : action.payload?.data?.message
        )
      } else {
        state.list = action.payload.data || {}
      }
    })
  },
})

export const gradeListSelector = (state) => state.grades.list

export const {} = gradesSlice.actions

export default gradesSlice.reducer
