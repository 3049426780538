import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { prepareCommonSelects } from 'helpers/prepares'
import { flowSelector } from 'store/dictionaries'
import Input from 'ui/components/forms/Input/'
import Select from 'ui/components/forms/Select/'

export const ProjectBaseInfo = ({ flows }) => {
  // TODO: Убрать селектор
  const formik = useFormikContext()
  const flowList = flows ? flows : useSelector(flowSelector)
  const flowOptions = prepareCommonSelects(flowList)

  return (
    <>
      <Input
        required={true}
        label="Название проекта"
        name="name"
        id="projectNameInput"
        placeholder="Введите название проекта"
        meta={formik.getFieldMeta('name')}
        {...formik.getFieldProps('name')}
      />
      <Input
        required={true}
        label="Символьный код"
        placeholder="Введите символьный код"
        name="slug"
        meta={formik.getFieldMeta('slug')}
        {...formik.getFieldProps('slug')}
      />
      <Input
        label="Ссылка на wiki"
        name="wiki_link"
        id="projectWikiInput"
        meta={formik.getFieldMeta('wiki_link')}
        {...formik.getFieldProps('wiki_link')}
      />
      <Select
        required={true}
        options={flowOptions}
        value={
          formik.getFieldMeta('workflow')
            ? formik.getFieldMeta('workflow').value
            : null
        }
        meta={formik.getFieldMeta('workflow')}
        error={
          formik.getFieldMeta('workflow').touched &&
          formik.getFieldMeta('workflow').error
        }
        onChange={(newValue) => {
          if (newValue) {
            formik.setFieldValue('workflow', newValue)
          } else {
            formik.setFieldValue('workflow', '')
          }
        }}
        label="Workflow"
        id="workflowSelect"
        defaultTitle="Любой"
        maxHeight="174px"
        placeholder="Не выбран"
      />
    </>
  )
}
