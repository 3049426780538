import styled from 'styled-components'
import { Colors } from 'utils/constants'

export const CheckboxCustom = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  height: 16px;
  border: 1px solid ${Colors.Black.TextSec};
  border-radius: 4px;
  transition: all 0.3s;
  background-color: #fff;
  margin-right: 9px;
  position: relative;
  color: ${Colors.Light.White};

  svg {
    width: 8px;
    height: 8px;
    opacity: 0;
  }
`

export const CheckboxWrap = styled.label`
  display: flex;
  align-content: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    ${CheckboxCustom} {
      border-color: ${Colors.Black.TextSec};
    }
  }
`

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);

  &:checked ~ ${CheckboxCustom} {
    background-color: ${Colors.DS.MainBlue};
    border-color: ${Colors.DS.MainBlue};
    svg {
      opacity: 1;
    }
  }
`

const CheckboxLabelStyled = styled.span`
  color: ${Colors.Black.ButtonsSidebar};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`

export const CheckboxLabel = ({ children }) => {
  return <CheckboxLabelStyled>{children}</CheckboxLabelStyled>
}
