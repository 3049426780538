import styled, { css } from 'styled-components'
import { Colors, Transition, breakpoints } from 'utils/constants'
import {
  Wrapper as MenuWrapper,
  List as MenuList,
  ProjectMenu,
  ProjectMenuBox,
  ProjectName,
  ProjectMenuTitle,
  ControlTitle,
  Elem,
} from './components/Menu/styled'

export const SidebarToggle = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Black.IconsSidebar};
  background-color: ${Colors.Black.ButtonsSidebar};
  border-radius: 8px;
`

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${breakpoints.mobileMax} {
    display: none;
  }
`

export const SidebarLogo = styled.div``

export const User = styled.div`
  padding: 0 16px;
`

export const UserHeader = styled.div`
  display: flex;
  align-items: center;
`

export const UserInfo = styled.div`
  width: calc(100% - 38px);
  padding-left: 16px;
`

export const UserAvatar = styled.div`
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const UserName = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 2px;
`

export const UserPosition = styled.div`
  color: ${Colors.Black.IconsSidebar};
`

export const UserControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
  padding-bottom: 24px;
`

export const UserLink = styled.div`
  width: 50%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  color: ${Colors.Black.IconsSidebar};
  background-color: ${Colors.Black.ButtonsSidebar};
  border-radius: 6px;
  transition: ${Transition.ms300};

  &:hover {
    color: ${Colors.Light.White};
  }
`

export const SidebarMenu = styled.div`
  position: relative;
  height: calc(100% - 182px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    background: ${Colors.Black.ButtonsSidebar};
  }

  [data-simplebar='init'] {
    height: 100%;
  }
`

export const SidebarRoot = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 272px;
  z-index: 10;
  color: ${Colors.Light.White};
  background-color: ${Colors.Black.TextBackground};
  --padding-left-content: 56px;

  ${(p) =>
    p.hide &&
    css`
      width: 56px;
      left: 0;

      ${SidebarMenu} {
        height: calc(100% - 64px);
      }

      ${User},
      ${SidebarLogo},
      ${ProjectName},
      ${ControlTitle} {
        display: none;
      }

      ${SidebarMenu} {
        &:before {
          left: 0;
          right: 0;
        }
      }

      ${MenuWrapper} {
        padding: 24px 0;
      }

      ${MenuList} {
        padding: 0 8px;
      }

      ${ProjectMenuTitle} {
        justify-content: center;
      }

      ${ProjectMenuBox} {
        background: transparent;
      }
    `};

  ${(p) =>
    p.theme === 'light' &&
    css`
      background-color: ${Colors.Light.CardsBack};
      color: ${Colors.Black.BlocksBackground};
      box-shadow: 0 0 1px 0px ${Colors.Black.TextSec};

      ${SidebarMenu} {
        &:before {
          background: #e6e8ec;
        }
      }

      ${ProjectMenu} {
        border-bottom-color: #e6e8ec;
      }

      ${SidebarToggle} {
        background-color: ${Colors.Light.White};
        color: ${Colors.Black.TextSec};
      }

      ${UserLink} {
        background-color: ${Colors.Light.White};
        color: #bebebe;

        &:first-child {
          color: #1e91e3;
        }

        &:hover {
          color: ${Colors.Black.BlocksBackground};
        }
      }

      ${ProjectMenuBox} {
        background-color: ${Colors.Light.White};
      }

      .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
        background-color: ${Colors.Black.BlueGrey};
      }
    `};

  ${(p) =>
    p.hide &&
    p.theme === 'light' &&
    css`
      ${ProjectMenuBox} {
        background-color: transparent;
      }
      ${Elem} {
        color: ${Colors.Black.ButtonsSidebar};
        background-color: transparent;
        &:hover {
          color: ${Colors.Black.BlocksBackground};
          background-color: ${Colors.Light.White};
        }
        &[aria-selected='true'] {
          background-color: ${Colors.Light.White};
          color: ${Colors.Black.BlocksBackground};
          &:hover {
            background-color: ${Colors.Light.White};
            color: ${Colors.Black.BlocksBackground};
          }
        }
      }
    `}

  @media ${breakpoints.mobileMax} {
    width: 100%;
    top: 48px;
    padding-top: 24px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    pointer-events: none;
    transform: translate3d(0, -100%, 0);
    transition: 0.4s transform cubic-bezier(0.165, 0.84, 0.44, 1);

    ${(p) =>
      p.opened &&
      css`
        pointer-events: all;
        transform: translate3d(0, 0, 0);
      `}
  }
`
