import { Fancybox as NativeFancybox } from '@fancyapps/ui'
import { useRef, useEffect } from 'react'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { formatDate } from 'helpers/formatDate'
import Icon from 'ui/components/Icon'
import {
  FileCard,
  FileDelete,
  FileInfo,
  FileTitle,
  FileImage,
  ButtonWrapper,
} from './styled'

export const LightBoxFileList = ({
  images,
  deleteFiles = null,
  options = {},
  delegate = '[data-fancybox]',
}) => {
  const containerRef = useRef(null)
  useEffect(() => {
    const container = containerRef.current

    const option = {
      ...options,
      Carousel: {
        infinite: false,
      },
      wheel: 'slide',
    }

    NativeFancybox.bind(container, delegate, option)

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  })

  return (
    <div ref={containerRef} className="lightbox-file-list">
      {images?.map((image) => (
        <div key={image.id} className="lightbox-file-list__wrapper">
          <ButtonWrapper>
            {deleteFiles && (
              <FileDelete
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  deleteFiles(image.id)
                }}
              >
                <Icon name="trash" size="16" />
              </FileDelete>
            )}
          </ButtonWrapper>
          <FileCard data-fancybox="gallery" href={image.link}>
            <FileImage>
              <img src={image.link} />
            </FileImage>
            <FileInfo>
              <FileTitle title={image.original_name}>
                {image.original_name}
              </FileTitle>
              <div>{formatDate(image.created_at)}</div>
            </FileInfo>
          </FileCard>
        </div>
      ))}
    </div>
  )
}
