import styled from 'styled-components'
import { PALETTE } from 'styles/constants'

export const SidebarModalLabelWrap = styled.div`
  padding: ${p => p.padding ? p.padding : '8px 32px'};
  background-color: ${PALETTE.paleGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SidebarModalLabelTitle = styled.div`
  color: ${PALETTE.grey};
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`