import classNames from 'classnames'
import Select, { components } from 'react-select'
import Checkbox from 'ui/components/forms/Checkbox'
import Icon from 'ui/components/Icon'
import Scrollbar from 'ui/components/Scrollbar'
import { Colors, Transition } from 'utils/constants'
import {
  StyledOption,
  OptionText,
  SelectWrap,
  SelectLabel,
  SelectError,
} from './styled'

const CLASSNAME_PREFIX = 'Select'

const customStyles = {
  option: (styles, state) => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: state.isFocused
        ? `${Colors.Light.CardsBack}`
        : 'transparent',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '16px',
      color: `${Colors.Black.BlocksBackground}`,
      outline: 'none !important',

      '&:active': {
        backgroundColor: `${Colors.Light.LinesGrey}`,
      },
    }
  },
  control: (styles, state) => ({
    ...styles,
    borderColor: `${Colors.Light.CardsBack}`,
    backgroundColor: `${Colors.Light.CardsBack}`,
    transition: `all ${Transition.ms300}`,
    height: '42px',
    minHeight: '42px',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none !important',
    borderColor: state.isFocused
      ? `${Colors.DS.MainBlue}`
      : `${Colors.Light.CardsBack}`,
    borderRadius: '8px',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: `${Colors.Black.TextSec}`,
    fontWeight: 400,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  // временно скрыл indicatorsContainer, до согласования с Полиной. т.к. Положение не совпадает с крестиком закрытия
  // indicatorsContainer: (styles) => ({
  //   ...styles,
  //   display: 'block',
  //   '.Select__indicator': {
  //     padding: '0 12px 0 0',
  //     height: '100%',
  //     alignItems: 'center',
  //   },
  //   svg: {
  //     width: '30px',
  //     height: '30px',
  //     transition: '0.3s ease',
  //   },
  // }),
  valueContainer: (styles) => ({
    ...styles,
    fontWeight: 500,
    fontSize: '14px',
    maxWidth: '100%',
    lineHeight: '17px',
    color: `${Colors.Black.TextSec}`,
    paddingLeft: '16px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 15,
    marginTop: '8px',
    boxShadow:
      '0px 2px 2px -1px rgba(27, 35, 44, 0.04), 0px 8px 16px -2px rgba(27, 36, 44, 0.12)',
    border: `1px solid ${Colors.Light.LinesGrey}`,
    borderRadius: '6px',
  }),
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="down" size="24" />
    </components.DropdownIndicator>
  )
}

const Menu = ({ children, selectProps: { maxHeight }, ...props }) => {
  return (
    <components.Menu {...props}>
      <Scrollbar maxHeight={maxHeight}>{children}</Scrollbar>
    </components.Menu>
  )
}

const Option = ({ isSelected, value, ...props }) => {
  return (
    <components.Option {...props}>
      <StyledOption title={value}>
        <Checkbox checked={isSelected} onChange={() => null} />{' '}
        <OptionText>{value}</OptionText>
      </StyledOption>
    </components.Option>
  )
}

const MultiValue = ({ children, ...props }) => {
  return <components.MultiValue {...props}>{children}</components.MultiValue>
}

const SelectWithCheckboxes = ({
  label,
  meta,
  error,
  required,
  upperLabel = true,
  ...props
}) => {
  const isTouched = meta && meta.touched
  const hasError = isTouched && meta.error
  return (
    <SelectWrap
      className={classNames({
        'has-error': hasError,
      })}
    >
      {label && upperLabel && (
        <SelectLabel>
          {label} {required && <span>*</span>}
        </SelectLabel>
      )}
      <Select
        isMulti
        components={{
          Option,
          MultiValue,
          DropdownIndicator,
          Menu,
        }}
        styles={customStyles}
        closeMenuOnSelect={false}
        captureMenuScroll={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        classNamePrefix={CLASSNAME_PREFIX}
        placeholder={`${label}${required ? '*' : ''}`}
        required
        {...props}
      />
      {hasError && <SelectError>{error}</SelectError>}
    </SelectWrap>
  )
}

export default SelectWithCheckboxes
