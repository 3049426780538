import { createSlice } from '@reduxjs/toolkit'
import {
  getEstimatesListAction,
  getEstimateDetailAction,
  createEstimateAction,
  updateEstimateAction,
} from './estimates.thunk'

const estimatesSlice = createSlice({
  name: 'estimates',
  initialState: {
    list: [],
    detail: null,
    loadingStatus: 'idle',
    create: {
      status: 'idle',
      errorMessage: '',
    },
  },
  reducers: {
    clearEstimateCreate: (state) => {
      state.create = {
        status: 'idle',
        errorMessage: '',
      }
    },
    clearEstimatesList: (state) => {
      state.list = []
    },
    clearEstimatesDetail: (state) => {
      state.detail = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEstimatesListAction.pending, (state) => {
        state.loadingStatus = 'pending'
      })
      .addCase(getEstimatesListAction.fulfilled, (state, action) => {
        state.list = action.payload.data || []
        state.loadingStatus = 'idle'
      })
    builder
      .addCase(getEstimateDetailAction.pending, (state) => {
        state.loadingStatus = 'pending'
      })
      .addCase(getEstimateDetailAction.fulfilled, (state, action) => {
        state.detail = action.payload.data
        state.loadingStatus = 'idle'
      })
    builder
      .addCase(createEstimateAction.pending, (state) => {
        state.create.status = 'pending'
      })
      .addCase(createEstimateAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.create.status = 'error'

          state.create.errorMessage = errors
            ? Object.values(errors).join(', ')
            : action.payload?.data?.message
        } else {
          state.create.status = 'success'
        }
      })
    builder
      .addCase(updateEstimateAction.pending, (state) => {
        state.create.status = 'pending'
      })
      .addCase(updateEstimateAction.fulfilled, (state, action) => {
        if (action.payload?.errorMessage) {
          const errors = action.payload?.data?.errors || null
          state.create.status = 'error'

          state.create.errorMessage = errors
            ? Object.values(errors).join(', ')
            : action.payload?.data?.message
        } else {
          state.create.status = 'success'
        }
      })
  },
})

export const estimatesListSelector = (state) => state.estimates.list
export const estimateDetailSelector = (state) => state.estimates.detail
export const createEstimateSelector = (state) => state.estimates.create
export const updateEstimateSelector = (state) => state.estimates.update
export const estimateLoadingStatusSelector = (state) =>
  state.estimates.loadingStatus

export const { clearEstimateCreate, clearEstimatesList, clearEstimatesDetail } = estimatesSlice.actions

export const estimatesReducer = estimatesSlice.reducer
