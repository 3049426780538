import styled from 'styled-components'
import { PALETTE } from 'styles/constants'

export const Button = styled.div`
  margin: 0 5px;
`
export const EditorWrap = styled.div`
  min-height: 100px;
  padding: 10px 10px 15px;
  border: 1px solid ${PALETTE.lightGrey};
`

export const EditorArea = styled.textarea`
  font-family: inherit;
  resize: none;
  overflow: hidden;
  width: 100%;
`
