import { SwitchWrap, SwitchInput, SwitchCustom, SwitchLabel } from './styled'

const Switch = ({ label, ...otherProps }) => {
  return (
    <SwitchWrap>
      <SwitchInput {...otherProps} />
      <SwitchCustom />
      <SwitchLabel>{label}</SwitchLabel>
    </SwitchWrap>
  )
}

export default Switch
