import styled from 'styled-components'

export const Text = styled.div`
  color: inherit;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`

export const TextStyle3 = props => (
  <Text className={props.className} {...props}>
    {props.children}
  </Text>
)
