import Icon from 'ui/components/Icon'
import {
  CheckboxWrap,
  CheckboxInput,
  CheckboxCustom,
  CheckboxLabel,
} from './styled'

const Checkbox = ({ label, ...otherProps }) => {
  return (
    <CheckboxWrap as={label ? 'label' : 'div'}>
      <CheckboxInput {...otherProps} />
      <CheckboxCustom>
        <Icon name="checkbox" />
      </CheckboxCustom>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrap>
  )
}

export default Checkbox
