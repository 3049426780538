import styled, { css } from 'styled-components'
import { Colors } from 'utils/constants'

export const LogoRoot = styled.div`
  display: flex;
  align-items: center;
`

export const LogoIcon = styled.div`
  width: 20px;
  height: 20px;
`

export const LogoTitle = styled.div`
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  padding-left: 12px;
  position: relative;
  top: 1px;
  color: ${Colors.Light.White};
  ${(p) =>
    p.dark &&
    css`
      color: ${Colors.Black.TextBackground};
    `}
`
