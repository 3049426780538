import Modal from '@mui/material/Modal'
import { useState, useEffect } from 'react'
import Icon from 'ui/components/Icon'
import { Wrapper, ModalTitle, Box, CloseButton } from './styled'

const ModalBasic = ({ size, title, open, handlerClose, children }) => {
  const [down, setDown] = useState(false)
  const [up, setUp] = useState(false)

  const handler = (event, setState) => {
    const currentTarget = event.currentTarget
    const target = event.target

    if (target.contains(currentTarget)) {
      setState(true)
    }
  }

  const handleMouseDown = (event) => handler(event, setDown)
  const handleMouseUp = (event) => handler(event, setUp)

  useEffect(() => {
    if (up && down) {
      handlerClose()
      setUp(false)
      setDown(false)
    }
  }, [up, down])

  return (
    <Modal open={!!open} onClose={handlerClose}>
      <Wrapper onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        <Box size={size} onClick={(e) => e.stopPropagation()}>
          {title && <ModalTitle>{title}</ModalTitle>}
          {children}
        </Box>
        <CloseButton onClick={handlerClose}>
          <Icon name="close" size={12} />
        </CloseButton>
      </Wrapper>
    </Modal>
  )
}

export default ModalBasic
