import makeRequest from 'api/'
import getFilterParams from 'helpers/getFilterParams'

export const getProjectTasks = async (slug, data, controller) => {
  const params = {}
  const orderParams = {}
  const metaParams = {
    page: data.page,
    count: data.count,
  }
  const filterParams = getFilterParams(data, [
    'user_id',
    'component_id',
    'type_id',
    'stage_id',
  ])

  if (data.order_by && data.order_type) {
    orderParams['order[by]'] = data.order_by
    orderParams['order[type]'] = data.order_type.toUpperCase()
  }
  if (data.task) {
    params['filter[name]'] = data.task
  }

  const response = await makeRequest({
    method: 'get',
    url: `/project/${slug}/list`,
    signal: controller ? controller.signal : null,
    params: {
      ...params,
      ...filterParams,
      ...metaParams,
      ...orderParams,
    },
  })

  return response
}
