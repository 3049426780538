import classNames from 'classnames'
import { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import {
  ImageUploaderWrap,
  ImageUploaderLabel,
  ImageUploaderPicker,
  ImageUploaderPreview,
} from './styled'

const ImageUploader = ({
  label,
  fileImage,
  onUpload,
  style,
  ...otherProps
}) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(fileImage)
  }, [fileImage])

  const onChange = (image) => {
    onUpload(image)
    setImage(image)
  }

  return (
    <ImageUploaderWrap style={style}>
      {label && <ImageUploaderLabel>{label}</ImageUploaderLabel>}

      <ImageUploading
        value={image}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="dataUrl"
        {...otherProps}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          isDragging,
          dragDrops,
        }) => (
          <ImageUploaderPicker
            tabIndex={0}
            className={classNames({ 'is-dragging': isDragging })}
            onClick={image ? null : onImageUpload}
            {...dragDrops}
          >
            {imageList &&
              imageList.map((img, index) => {
                return (
                  <ImageUploaderPreview
                    src={img.dataUrl}
                    alt=""
                    key={index}
                    onClick={() => onImageUpdate(index)}
                  />
                )
              })}
          </ImageUploaderPicker>
        )}
      </ImageUploading>
    </ImageUploaderWrap>
  )
}

export default ImageUploader
