import styled from 'styled-components'

export const TagName = styled.div`
  background-color: #${(p) => p.background};
  color: #${(p) => p.color};
  display: flex;
  width: ${(p) => (p.width ? p.width : '60%')};
  text-align: left;
  padding: 10px;
  border-radius: 6px;
`
export const TagItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ButtonCell = styled.div`
  width: 30%;
`
