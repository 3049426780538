import styled, { css } from 'styled-components'
import { PALETTE, BREAKPOINTS } from 'styles/constants'
import { Colors } from 'utils'

export const Card = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: 0.3s ease-out;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:hover {
    color: ${PALETTE.lightPrimary};
    transition: 0.3s ease-out;
  }
  a {
    display: flex;
    align-items: center;
    transition: 0.3s ease-out;

    @media (max-width: ${BREAKPOINTS.mobile}) {
      ${(p) =>
        p.direction === 'vertical' &&
        css`
          flex-direction: column;
          align-items: flex-start;
        `}
    }

    &:hover {
      color: ${PALETTE.lightPrimary};
    }
  }
`

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 48px;
    height: 48px;
  }
`

// export const Status = styled.div`
//   position: absolute;
//   bottom: -1px;
//   right: -1px;
//   width: 9px;
//   height: 9px;
//   background: ${PALETTE.green};
//   border: 1px solid ${PALETTE.white};
//   border-radius: 50%;

//   @media (max-width: ${BREAKPOINTS.mobile}) {
//     bottom: 2px;
//     right: 2px;
//   }
// `

export const Info = styled.div`
  width: calc(100% - 32px);
  padding-left: 8px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    padding: 5px !important;
    width: fit-content;
  }
`

export const Name = styled.div`
  color: ${Colors.Black.ButtonsSidebar};
  font-weight: 500;
  font-size: 13px;
`

export const Position = styled.div`
  font-size: 12px;
  color: ${PALETTE.grey};
  margin-top: 5px;
`

export const AdditionalText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${PALETTE.grey};
  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 12px;
  }
`
