import styled from 'styled-components'
import { BREAKPOINTS, PALETTE } from 'styles/constants'
import { Colors } from 'utils'

export const FileCard = styled.div`
  cursor: pointer;
  max-width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  color: #999;
  background-color: ${PALETTE.neutralGrey};
  border: 1px solid ${PALETTE.neutralGrey};
  position: relative;
  border-radius: 4px;
  transition: 300ms ease-out;

  img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    background-color: ${PALETTE.white};
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    &:nth-child(even) {
      margin-right: 0px;
    }
  }
`

export const FileDelete = styled.button`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${PALETTE.grey};
  background: transparent;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover {
    color: ${PALETTE.popPink};
  }
`
export const ButtonWrapper = styled.div`
  background-color: #f4f6f8;
  display: flex;
  height: 32px;
  padding: 8px 10px 8px 0;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  z-index: 100;
  background: transparent;
  right: 0;
  top: 0;
`

export const FileInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  color: #abbed1;
`

export const FileTitle = styled.div`
  width: 100%;
  color: ${PALETTE.primary};
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
`

export const FileImage = styled.div`
  max-width: 100%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  &:empty {
    background-image: url('/images/icons/doc-text-fill.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: ${Colors.Light.White};
  }

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`
