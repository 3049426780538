import { useRouter } from 'next/router'
import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBugsReport } from 'api/reports'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { ProjectType } from 'store/constants'
import { openUserRoleModalAction } from 'store/modals'
import { projectInfoSelector } from 'store/projects'
import { currentUserSelector } from 'store/users'
import { Colors } from 'utils/constants'
import { getLinkWithQuery } from '../utils'

export function useMenu() {
  const router = useRouter()
  const dispatch = useDispatch()
  const projectSlug = router.query.projectSlug
  const currentUser = useSelector(currentUserSelector)
  const projectInfo = useSelector(projectInfoSelector)
  const { isManager } = usePrevilegies()
  const [projectMenu, setProjectMenu] = useState([])
  const [generalMenu, setGeneralMenu] = useState([])
  const [editProjectOpened, setEditProjectOpened] = useState(false)

  const openUserRoleModal = () =>
    dispatch(openUserRoleModalAction({ projectSlug }))

  const reportUrl = getLinkWithQuery(`/projects/${projectSlug}/report`, {
    project: projectInfo?.id,
    group: isManager ? 'user' : 'task',
    hideProjectSelect: 1,
    hideUserSelect: isManager ? 0 : 1,
    getReport: 1,
    user: isManager ? '' : currentUser?.id,
  })

  const canEditProject = useMemo(
    () => projectInfo?.capabilities?.includes('edit_project'),
    [projectInfo]
  )

  const visibleEstimateMenuLink =
    ProjectType.Fix === projectInfo?.project_type?.id

  const getBugsReportHandle = async () => {
    if (projectInfo?.id) {
      await getBugsReport(projectInfo?.id)
    }
  }

  useEffect(() => {
    const settingsMenu = []

    if (canEditProject && visibleEstimateMenuLink) {
      settingsMenu.push({
        icon: 'estimates',
        title: 'Сметы',
        href: `/projects/${projectSlug}/estimates`,
        asHref: `/projects/[projectSlug]/estimates`,
        iconColor: `${Colors.Orange.OrangeMain}`,
      })
    }

    if (canEditProject) {
      settingsMenu.push(
        {
          icon: 'performers',
          title: 'Исполнители',
          iconColor: `${Colors.Yellow.Lemon}`,
          onClick: openUserRoleModal,
        },
        {
          icon: 'settings',
          title: 'Настройки',
          iconColor: `${Colors.Black.IconsSidebar}`,
          onClick: () => setEditProjectOpened(true),
        }
      )
    }

    setProjectMenu([
      {
        icon: 'kanban',
        title: 'Канбан',
        href: `/projects/${projectSlug}`,
        asHref: `/projects/[projectSlug]`,
        iconColor: `${Colors.DS.MainBlue}`,
      },
      {
        icon: 'my-tasks',
        title: 'Задачи',
        href: `/projects/${projectSlug}/tasks`,
        asHref: `/projects/[projectSlug]/tasks`,
        iconColor: `${Colors.Green.GreenHerbal}`,
      },
      {
        icon: 'backlog',
        title: 'Бэклог',
        href: `/projects/${projectSlug}/backlog`,
        asHref: `/projects/[projectSlug]/backlog`,
        iconColor: `${Colors.Lagoon.Lagoon}`,
      },
      {
        icon: 'time',
        title: 'Время',
        href: reportUrl,
        asHref: `/projects/[projectSlug]/report`,
        iconColor: `${Colors.Purple.MPurple}`,
      },
      {
        icon: 'reports',
        title: 'Отчет по тестированию',
        onClick: () => getBugsReportHandle(),
      },
      ...settingsMenu,
    ])
  }, [projectInfo, canEditProject, currentUser, projectSlug])

  useEffect(() => {
    const menuItems = [
      {
        icon: 'projects',
        title: 'Проекты',
        href: `/projects`,
        asHref: `/projects`,
        iconColor: `${Colors.DS.MainBlue}`,
      },
      {
        icon: 'my-tasks',
        title: 'Мои задачи',
        href: `/users/${currentUser?.id}`,
        asHref: `/users/[id]`,
        iconColor: `${Colors.Green.GreenHerbal}`,
      },
      {
        icon: 'user',
        title: 'Пользователи',
        href: `/users`,
        asHref: `/users`,
        iconColor: `${Colors.Yellow.Lemon}`,
      },
      {
        icon: 'reports',
        title: 'Отчеты',
        href: `/reports`,
        asHref: `/reports`,
        iconColor: `${Colors.Red.Raspberry}`,
      },
      {
        icon: 'grades',
        title: 'Грейды',
        href: `/grades/list`,
        asHref: `/grades/list`,
        iconColor: `${Colors.Green.DarDarkMGreenk}`,
      },
    ]

    if (currentUser?.is_admin || currentUser?.can_grade) {
      const gradeItems = [
        {
          icon: 'gradesList',
          title: 'Справочник грейдов',
          href: `/grades`,
          asHref: `/grades`,
        },
        {
          icon: 'grading',
          title: 'Грейдирование',
          href: `/graduation`,
          asHref: `/graduation`,
        },
      ]

      menuItems.push(...gradeItems)
    }

    if (isManager) {
      menuItems.push({
        icon: 'reports',
        title: 'Недельное планирование',
        href: `/weekly-plan`,
        asHref: `/weekly-plan`,
        iconColor: `${Colors.Yellow.DYellow}`,
      })
    }

    setGeneralMenu(menuItems)
  }, [currentUser])

  return {
    generalMenu,
    projectMenu,
    editProjectOpened,
    setEditProjectOpened,
  }
}
