import { Editor } from 'ui/components/forms/Editor'
import EditorInput from 'ui/components/forms/EditorInput'

export const EditorWrapper = ({
  required,
  label,
  value,
  name,
  meta,
  onChange,
  editorVersion,
}) => {
  switch (editorVersion) {
    case 2:
      return (
        <Editor
          required={required}
          label={label}
          name={name}
          meta={meta}
          value={value}
          onChange={onChange}
        />
      )
    default:
      return (
        <EditorInput
          required={required}
          label={label}
          name={name}
          meta={meta}
          value={value}
          onChange={onChange}
        />
      )
  }
}
