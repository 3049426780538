import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { downloadFile } from 'helpers/downloadFile'
import { formatDate } from 'helpers/formatDate'
import FileChip from 'ui/components/FileChip/'
import Icon from 'ui/components/Icon'
import { LightBoxFileList } from 'ui/components/LightBoxFileList/LightBoxFileList'
import {
  Wrapper,
  Dragzone,
  FileList,
  FileCard,
  FileDelete,
  FileImage,
  FileInfo,
  FileTitle,
  FileDate,
  FileActionsContainer,
} from './styled'

const imgTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp']

const getFileImage = (fileLink) => {
  const exts = fileLink.split('.')

  const isImage = imgTypes.includes(exts?.[exts?.length - 1])
  if (isImage) {
    return <img src={fileLink} />
  }

  return null
}

const FileUploader = ({
  items = [],
  showUploader = false,
  smallPreview = false,
  sendFileHandler,
  deleteFileHandler,
  canDelete = true,
  id,
}) => {
  const [files, setFiles] = useState(items)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  useEffect(() => {
    setFiles(items)
  }, [items])

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      sendFileHandler(acceptedFiles, updateFiles)
    }
  }, [acceptedFiles])

  const updateFiles = (data) => {
    setFiles([...files, ...data])
  }

  const deleteFiles = (fileId) => {
    const updateAfterDelete = () =>
      setFiles(files.filter((file) => file.id !== fileId))
    deleteFileHandler(fileId, updateAfterDelete)
  }

  const downloadFileBtn = async (event, file) => {
    event.preventDefault()
    downloadFile(file.original_name, file.link)
  }
  const zeroImageFileList = files.filter((file) => {
    const ext = file.original_name.split('.').pop()
    return !imgTypes.includes(ext)
  })

  const filesRender = zeroImageFileList.map((file) => (
    <FileCard
      href={file.link}
      key={file.id}
      onClick={(event) => downloadFileBtn(event, file)}
    >
      <FileActionsContainer>
        {canDelete && (
          <FileDelete
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              deleteFiles(file.id)
            }}
          >
            <Icon name="trash" size="16" />
          </FileDelete>
        )}
      </FileActionsContainer>
      <FileImage>{getFileImage(file.link)}</FileImage>
      <FileInfo>
        <FileTitle title={file.original_name}>{file.original_name}</FileTitle>
        <FileDate>{formatDate(file.created_at)}</FileDate>
        {/* <FileSize>{file.size}</FileSize> */}
      </FileInfo>
    </FileCard>
  ))

  const filesChipRender = files
    .filter((file) => {
      const ext = file.original_name.split('.').pop()
      return !imgTypes.includes(ext)
    })
    .map((file) => (
      <FileChip
        key={file.id}
        fileId={file.id}
        title={file.original_name}
        canDelete={true}
        deleteHandler={deleteFiles}
      />
    ))
  const images = files.filter((image) => {
    let ext = image.original_name.split('.').pop()
    return imgTypes.includes(ext)
  })

  return (
    <Wrapper>
      {showUploader && (
        <Dragzone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} id={id} />
          <Icon name="icon-clip" size="18" />
          <p>Перетащите сюда файлы или загрузите файл до 100мб</p>
        </Dragzone>
      )}
      <FileList>{smallPreview ? filesChipRender : filesRender}</FileList>
      <LightBoxFileList images={images} deleteFiles={deleteFiles} />
    </Wrapper>
  )
}

export default FileUploader
