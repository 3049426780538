import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getComponent,
  getPriority,
  getTaskType,
  getFlow,
  getTaskStage,
  getGenders,
  getTags,
  createTag,
  deleteTag,
  getEpics,
  getProjectType,
} from 'api/dictionaries/'

export const getComponentAction = createAsyncThunk(
  'dictionaries/getComponent',
  async () => {
    return await getComponent()
  }
)

export const getPriorityAction = createAsyncThunk(
  'dictionaries/getPriority',
  async () => {
    return await getPriority()
  }
)

export const getTaskTypeAction = createAsyncThunk(
  'dictionaries/getTaskType',
  async () => {
    return await getTaskType()
  }
)

export const getTaskStageAction = createAsyncThunk(
  'dictionaries/getTaskStage',
  async () => {
    return await getTaskStage()
  }
)

export const getFlowAction = createAsyncThunk(
  'dictionaries/getFlow',
  async () => {
    return await getFlow()
  }
)
export const getGenderListAction = createAsyncThunk(
  'dictionaries/getGenders',
  async () => {
    return await getGenders()
  }
)
export const getTagListAction = createAsyncThunk(
  'dictionaries/getTagList',
  async () => {
    return await getTags()
  }
)
export const getEpicListAction = createAsyncThunk(
  'dictionaries/getEpicList',
  async (data) => {
    return await getEpics(data)
  }
)
export const createTagAction = createAsyncThunk(
  'dictionaries/createTagAction',
  async (data) => {
    return await createTag(data)
  }
)
export const deleteTagAction = createAsyncThunk(
  'dictionaries/deleteTagAction',
  async (id) => {
    return await deleteTag(id)
  }
)

export const getProjectTypeAction = createAsyncThunk(
  'dictionaries/getProjectType',
  async () => {
    return await getProjectType()
  }
)
