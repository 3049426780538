import { CircularProgress } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useDeleteProjectUserMutation,
  useGetProjectInfoQuery,
  useUpdateProjectUserMutation,
} from 'apiv2'
import { groupRoleByUserId } from 'helpers/groupRoleByUserId'
import { closeUserRoleModal, userRoleModalSelector } from 'store/modals'
import {
  getProjectInfoAction,
  clearProjectInfo,
  getProjectUsersAction,
} from 'store/projects'
import ModalBasic from 'ui/components/ModalBasic/'
import { SelectNewUser } from './components/SelectNewUser'
import { SelectUser } from './components/SelectUser'
import { usePreviousSlug, useRoleList, useUserList } from './hooks'
import { LoadingContainer } from './styled'

const ModalUserRole = () => {
  const modalInfo = useSelector(userRoleModalSelector)
  const title = modalInfo?.title
  const open = modalInfo?.open
  const slug = modalInfo?.projectSlug
  const dispatch = useDispatch()
  const router = useRouter()
  const projectSlug = router.query.projectSlug
  const handlerClose = () => {
    dispatch(closeUserRoleModal())
    dispatch(getProjectInfoAction(projectSlug))
    dispatch(getProjectUsersAction(projectSlug))

    // TODO переделать удаление сотрудников из проекта на rtq-query
    dispatch(getProjectInfoAction(projectSlug))
  }
  useEffect(() => {
    return () => dispatch(clearProjectInfo())
  }, [])
  const {
    data: projectInfo,
    isLoading: isProjectInfoLoading,
    isSuccess,
  } = useGetProjectInfoQuery(slug, {
    skip: !slug,
  })
  const [userList, updateUserList] = useUserList({
    open,
    users: projectInfo?.users,
  })
  const roleList = useRoleList({ open })
  const previousSlug = usePreviousSlug(isSuccess, slug)

  const [updateProjectUserMutation] = useUpdateProjectUserMutation()
  const [deleteProjectUserMutation] = useDeleteProjectUserMutation()

  const isFullData = roleList && projectInfo && userList

  return (
    <ModalBasic
      size="medium"
      open={open}
      handlerClose={handlerClose}
      title={title}
    >
      {!isFullData && isProjectInfoLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {isFullData && !isProjectInfoLoading && previousSlug === slug && (
        <>
          <SelectUser
            roleList={roleList}
            userList={userList}
            updateProjectUserMutation={updateProjectUserMutation}
            deleteProjectUserMutation={deleteProjectUserMutation}
            users={groupRoleByUserId(projectInfo.users)}
            slug={slug}
          />
          <SelectNewUser
            roleList={roleList}
            userList={userList}
            updateProjectUserMutation={updateProjectUserMutation}
            updateUserList={updateUserList}
            slug={slug}
          />
        </>
      )}
    </ModalBasic>
  )
}

const WrapperUserRole = () => {
  const modalInfo = useSelector(userRoleModalSelector)
  const open = modalInfo?.open
  const slug = modalInfo?.projectSlug

  useEffect(() => {
    if (slug) {
      sessionStorage.setItem('slug', slug)
    }
  }, [slug])

  if (!open) {
    return null
  }

  return <ModalUserRole />
}

export default WrapperUserRole
