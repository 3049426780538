import {
  Control,
} from './styled'

const ControlButton = ({
  theme = 'base',
  onClick,
  children,
  active
}) => (
  <Control
    type="button"
    theme={theme}
    onClick={onClick}
    active={active}
  >
    {children}
  </Control>
)

export default ControlButton
