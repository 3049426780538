/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { removeCookie } from 'helpers/cookie'
import { currentUserSelector } from 'store/users'
import Icon from '../Icon'
import Scrollbar from '../Scrollbar'
import { Menu, Logo } from './components'
import {
  SidebarRoot,
  SidebarHeader,
  SidebarLogo,
  User,
  UserHeader,
  UserInfo,
  UserAvatar,
  UserName,
  UserPosition,
  UserControls,
  UserLink,
  SidebarMenu,
  SidebarToggle,
} from './styled'
import { path } from 'utils/constants/path'

const { defaultAvatarPath } = path

const getImage = (item) => {
  return item?.avatar ? item.avatar.link : defaultAvatarPath
}

const Sidebar = ({ opened, setMenuOpened, theme }) => {
  const router = useRouter()
  const currentUser = useSelector(currentUserSelector)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]

    body.style.setProperty('--padding-left-content', hidden ? '56px' : '272px')
  }, [hidden])

  const logoutHandler = () => {
    removeCookie('ac_token')
    router.push('/auth')
  }

  return (
    <SidebarRoot hide={hidden} opened={opened} theme={theme}>
      <SidebarHeader>
        <SidebarLogo>
          <Link href="/">
            <a>
              <Logo dark={theme === 'light'} />
            </a>
          </Link>
        </SidebarLogo>
        <SidebarToggle
          hide={hidden}
          onClick={() => setHidden(!hidden)}
          title={hidden ? 'Развернуть' : 'Свернуть'}
        >
          <Icon name={hidden ? 'sidebar-open' : 'sidebar-close'} />
        </SidebarToggle>
      </SidebarHeader>
      <User>
        <UserHeader>
          <UserAvatar>
            <img
              src={getImage(currentUser)}
              onError={(e) => (e.currentTarget.src = defaultAvatarPath)}
            />
          </UserAvatar>
          <UserInfo>
            <UserName>
              {currentUser?.name} {currentUser?.surname}
            </UserName>
            <UserPosition>{currentUser?.position}</UserPosition>
          </UserInfo>
        </UserHeader>
        <UserControls>
          <Link href={'/users/[userId]'} as={`/users/${currentUser?.id}`}>
            <UserLink as="a" onClick={() => setMenuOpened(false)}>
              <Icon name="user" size="12" />
              Профиль
            </UserLink>
          </Link>
          <UserLink onClick={logoutHandler}>Выйти</UserLink>
        </UserControls>
      </User>
      <SidebarMenu>
        <Scrollbar>
          <Menu setMenuOpened={setMenuOpened} theme={theme} />
        </Scrollbar>
      </SidebarMenu>
    </SidebarRoot>
  )
}

export default Sidebar
