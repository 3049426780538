import { saveAs } from 'file-saver';

export const downloadFile = async (fileName, fileLink) => {
  try {
    const blob = await fetch(fileLink);
    saveAs(await blob.blob(), fileName);
    return true;
  } catch (e) {
    return false;
  }
}
