import { useEffect, useState } from 'react'
import { useGetRoleListQuery, useGetUserListQuery } from 'apiv2'
import { prepareRoleList, prepareUserList } from 'helpers/prepares'

export function useUserList({ open, users }) {
  const [availableUserList, setAvailableUserList] = useState([])
  const { data } = useGetUserListQuery(null, { skip: !open })

  useEffect(() => {
    if (data?.length) {
      setAvailableUserList(prepareUserList(data))
    }
  }, [data])

  const updateUserList = () => {
    const filtered = availableUserList.filter(({ id }) => {
      return !users.some((employee) => employee.id === id)
    })

    setAvailableUserList(filtered)
  }

  return [availableUserList, updateUserList]
}

export function useRoleList({ open }) {
  const [preparedRoleList, setPreparedRolesList] = useState([])
  const { data: roleList } = useGetRoleListQuery(null, { skip: !open })

  useEffect(() => {
    if (roleList?.length) {
      setPreparedRolesList(prepareRoleList(roleList))
    }
  }, [roleList])

  return preparedRoleList
}

export function usePreviousSlug(isSuccess, slug) {
  const [previousSlug, setPreviousSlug] = useState(
    sessionStorage.getItem('slug')
  )

  useEffect(() => {
    if (isSuccess) {
      setPreviousSlug(slug)
    }
  }, [isSuccess])

  return previousSlug
}
