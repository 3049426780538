export const Priority = {
  1: 'high',
  2: 'middle',
  3: 'low',
}

export const PriorityCyrillic = {
  1: 'Высокий',
  2: 'Средний',
  3: 'Низкий',
}

export const TaskTypes = {
  1: 'bug', // Баг
  2: 'task', // Задача
  3: 'improvement', // Улучшение
  4: 'new functionality', // Новая функциональность
  5: 'epic', // Эпик
  6: 'release', // Релиз
  7: 'backlog', // Бэклог
  8: 'guarantee', // Гарантия
}

export const ComponentTypes = {
  1: 'design', // Дизайн
  2: 'markup', // Вёрстка
  3: 'dev', // Разработка
  4: 'testing', // Тестирование
  5: 'content', // Контент
  6: 'manager', // Менеджмент
  7: 'frontend', // Фронтенд-разработка
  8: 'devops', // Администрирование
}
