export function sortTasksByLexorank(a, b) {
  if (!a.rank && b.rank) {
    return -1;
  }
  if (a.rank && !b.rank) {
    return 1;
  }
  if (!a.rank || !b.rank) {
    return 0;
  }

  if (a.rank === b.rank) {
    return 0;
  }

  return a.rank > b.rank ? 1 : -1;
}
