import styled from 'styled-components'
import { PALETTE, BREAKPOINTS } from 'styles/constants'
import { TextStyle3 } from 'ui/components/typography/TextStyle3'

const AddHeaderStyled = styled.div`
  padding: 32px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${PALETTE.paleGrey};
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : 0)};
  position: relative;
`
export const AddResetButton = styled.button`
  color: ${PALETTE.primary};
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
  line-height: 15px;
`

export const AddInner = styled.div`
  padding: 32px;
`

export const AddButtonGroup = styled.div`
  margin-top: auto;
  border-top: 1px solid ${PALETTE.paleGrey};

  button {
    &:nth-of-type(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-of-type(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  ${AddInner} {
    display: flex;
    align-items: center;
  }
`

export const AddBody = styled.div`
  flex-grow: 1;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    max-height: calc(100vh - 260px);
    overflow: auto;
  }
`

export const AddHeader = (props) => {
  return (
    <AddHeaderStyled {...props}>
      <TextStyle3>{props.title}</TextStyle3>
    </AddHeaderStyled>
  )
}
