import App from 'next/app'
import Head from 'next/head'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import api from 'api/axios'
import { getJwtFromReq } from 'helpers/getJwtFromReq'
import store from 'store/index'
import Layout from 'ui/components/Layout/'

import 'react-datepicker/dist/react-datepicker.css'
import 'simplebar/dist/simplebar.min.css'
import '@asseinfo/react-kanban/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/global.scss'
import 'styles/libs/mui.scss'

function MyApp({ Component, pageProps, accessToken }) {
  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/images/favicon/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/images/favicon/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/images/favicon/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/images/favicon/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href="/images/favicon/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/images/favicon/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href="/images/favicon/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/images/favicon/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-196x196.png"
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-128.png"
          sizes="128x128"
        />
        <meta name="application-name" content="&nbsp;" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="msapplication-TileImage"
          content="/images/favicon/mstile-144x144.png"
        />
        <meta
          name="msapplication-square70x70logo"
          content="/images/favicon/mstile-70x70.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="/images/favicon/mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="/images/favicon/mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/images/favicon/mstile-310x310.png"
        />
      </Head>
      <Provider store={store}>
        <CookiesProvider>
          <Layout isAuthorized={accessToken}>
            <Component {...pageProps} />
            <ToastContainer />
          </Layout>
        </CookiesProvider>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = async (ctx) => {
  const accessToken = getJwtFromReq(ctx.ctx.req)

  if (accessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  const appProps = await App.getInitialProps(ctx)

  return { ...appProps, accessToken }
}

export default MyApp
