import classNames from 'classnames'
import { useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  InputWrap,
  InputLabel,
  InputError,
} from 'ui/components/forms/Input/styled'
import Icon from 'ui/components/Icon'
import BlockButton from './components/BlockButton'
import { Toolbar } from './components/components'
import { addTextStyle, addListStyle, addCodeStyle } from './conversion'
import { EditorArea, EditorWrap } from './styled'

const EditorInput = ({
  label,
  // placeholder = '',
  value = '',
  required,
  onChange,
  // resetValue = false,
  meta,
  // ...props
}) => {
  const isTouched = meta && meta.touched
  const hasError = isTouched && meta.error
  const areaId = uuidv4() // необходим уникальный id для каждого textarea на странице

  const areaRef = useRef(null)

  const addBold = () => {
    const { newValue } = addTextStyle(value, 'bold', areaId)
    onChange(newValue)
  }
  const addItalic = () => {
    const { newValue } = addTextStyle(value, 'italic', areaId)
    onChange(newValue)
  }

  const addNumberedList = () => {
    const { newValue } = addListStyle(value, 'numbered', areaId)
    onChange(newValue)
  }

  const addMarkerList = () => {
    const { newValue } = addListStyle(value, 'marker', areaId)
    onChange(newValue)
  }

  const addCode = () => {
    const { newValue } = addCodeStyle(value, areaId)
    onChange(newValue)
  }

  // работает, но пока не добавляем. ps нужна иконка
  // const addQuotes = () => {
  //   const { newValue } = addQuotesStyle(value)
  //   onChange(newValue)
  // }

  useEffect(() => {
    if (areaRef.current) {
      areaRef.current.style.height = `${areaRef.current.scrollHeight}px`
    }
  }, [])

  const handleInput = (event) => {
    const currentTarget = event.currentTarget
    currentTarget.style.height = `${currentTarget.scrollHeight}px`
  }

  return (
    <InputWrap
      className={classNames({
        'has-error': hasError,
      })}
    >
      {label && (
        <InputLabel>
          {label} {required && <span>*</span>}
        </InputLabel>
      )}
      <EditorWrap>
        <Toolbar>
          <BlockButton
            onClick={addBold}
            icon={<Icon name="text-bold" size="24" />}
          />
          <BlockButton
            onClick={addItalic}
            icon={<Icon name="text-italic" size="24" />}
          />
          <BlockButton
            onClick={addCode}
            icon={<Icon name="code" size="24" />}
          />
          <BlockButton
            onClick={addNumberedList}
            icon={<Icon name="numbered-list" size="24" />}
          />
          <BlockButton
            onClick={addMarkerList}
            icon={<Icon name="bulleted-list" size="24" />}
          />
          {/*<BlockButton onClick={addQuotes} icon={<BulletedListIcon />} />*/}
          {/*<MarkButton format="underline" icon={<Icon name="underline" size="24" />} />*/}
        </Toolbar>
        <EditorArea
          ref={areaRef}
          onInput={handleInput}
          id={areaId}
          cols="80"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </EditorWrap>
      {hasError && <InputError>{meta.error}</InputError>}
    </InputWrap>
  )
}
export default EditorInput
