import makeRequest from 'api/'
import filterParamsToQuery from '../../helpers/filterParamsToQuery'

export const getResultTable = async (filters = {}) => {
  const params = filterParamsToQuery(filters)
  return await makeRequest({
    method: 'get',
    url: params ? `/weekly-plans/result?${params}` : '/weekly-plans/result',
  })
}

export const getTable = async (filters = {}) => {
  const params = filterParamsToQuery(filters)
  return await makeRequest({
    method: 'get',
    url: params ? `/weekly-plans?${params}` : '/weekly-plans',
  })
}

export const createRow = async (data) => {
  return await makeRequest({
    method: 'post',
    url: `/weekly-plans`,
    data,
  })
}

export const updateRow = async (id, data) => {
  return await makeRequest({
    method: 'patch',
    url: `/weekly-plans/${id}`,
    data,
  })
}

export const deleteRow = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/weekly-plans/${id}`,
  })
}

export const getDateRange = async () => {
  return await makeRequest({
    method: 'get',
    url: `/weekly-plans/ranges`,
  })
}
