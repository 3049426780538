export const PALETTE = {
  darkBlue: '#304156',
  midBlue: '#29384A',
  primary: '#0393FF',
  lightPrimary: '#0FABFF',
  lightPrimaryMiddle: '#4B93ED',
  grey: '#828D99',
  midGrey: '#B3C0CE',
  lightGrey: '#E1E5E9',
  neutralGrey: '#F4F6F8',
  paleGrey: '#f0f1f2',
  white: '#FFFFFF',
  whiteSmoke: '#FAFBFB',
  green: '#8CD31A',
  yellow: '#FFDD00',
  popPink: '#EB597B',
  orange: '#FF8800',
  violet: '#7209b7',
  black: '#222222',
}

export const BREAKPOINTS = {
  small: '320px',
  mobile: '767px',
  medium: '768px',
  tabletAbove: '1200px',
  tablet: '1199px',
  large: '1366px',
  xlarge: '1920px',
}

export const ScreenSize = {
  small: `(max-width: ${BREAKPOINTS.small})`,
  mobile: `(max-width: ${BREAKPOINTS.mobile})`,
  medium: `(max-width: ${BREAKPOINTS.medium})`,
  tabletAbove: `(max-width: ${BREAKPOINTS.tabletAbove})`,
  tablet: `(max-width: ${BREAKPOINTS.tablet})`,
  large: `(max-width: ${BREAKPOINTS.large})`,
  xlarge: `(max-width: ${BREAKPOINTS.xlarge})`,
}
