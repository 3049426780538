import { Button, Icon } from '../components'

const BlockButton = ({ onClick, icon }) => {
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault()
        onClick()
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default BlockButton
