import styled from 'styled-components'
import { BREAKPOINTS, PALETTE } from 'styles/constants'

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
  margin-top: 10px;

  button {
    font-size: 12px;
    width: auto;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin: 0;
  }
`

export const FieldGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0 16px;

  & > div,
  label {
    width: calc(${(p) => 100 / p.count}% - 15px);
    flex: 0 0 calc(${(p) => 100 / p.count}% - 15px);

    @media (max-width: ${BREAKPOINTS.mobile}) {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 10px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin-bottom: 10px;
  }
`

export const UploaderContainer = styled.div`
  margin-bottom: 20px;
`

export const NotificationLink = styled.span`
  color: ${PALETTE.lightPrimary};
`

export const SelectWithCheckboxesWrap = styled.div``

export const DateGroup = styled.div`
  display: flex;
  margin-bottom: 28px;

  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const DateItem = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }

  .react-datepicker__close-icon {
    display: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 2px;

  h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
  }
`
