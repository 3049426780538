import makeRequest from 'api/'

export const getEstimatesList = async (projectSlug) => {
  return await makeRequest({
    method: 'get',
    url: `/project/${projectSlug}/estimate`,
  })
}

export const getEstimateDetail = async (id) => {
  return await makeRequest({
    method: 'get',
    url: `/estimate/${id}`,
  })
}

export const deleteEstimate = async (id) => {
  return await makeRequest({
    method: 'delete',
    url: `/estimate/${id}`,
  })
}

export const createEstimate = async ({ projectSlug, data }) => {
  return await makeRequest({
    method: 'post',
    url: `/project/${projectSlug}/estimate`,
    data,
  })
}

export const updateEstimate = async ({ id, data }) => {
  return await makeRequest({
    method: 'put',
    url: `/estimate/${id}`,
    data,
  })
}
