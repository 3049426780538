import styled, { css } from 'styled-components'

export const Svg = styled.svg`
  width: 16px;
  height: 16px;

  ${(p) =>
    p.size &&
    css`
      width: ${p.size}px;
      height: ${p.size}px;
    `}
`
