export const size = {
  mobile: 767,
  laptop: 1439,
  tablet: 1199,
  desktop: 1919,
}

export const breakpoints = {
  mobileMin: `(min-width: ${size.mobile + 1}px)`,
  mobileMax: `(max-width: ${size.mobile}px)`,
  laptopMin: `(min-width: ${size.laptop + 1}px)`,
  laptopMax: `(max-width: ${size.laptop}px)`,
  desktopMin: `(min-width: ${size.desktop + 1}px)`,
  desktopMax: `(max-width: ${size.desktop}px)`,
  tabletMin: `(min-width: ${size.tablet + 1}px)`,
  tabletMax: `(max-width: ${size.tablet}px)`,
}
