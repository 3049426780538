export default function reduceEpicParams(arrayOfIds) {
  return arrayOfIds.length
    ? arrayOfIds.reduce((accumulator, currentValue, index) => {
        return {
          ...accumulator,
          [`filter[epic_ids][${index}]`]: currentValue,
        }
      }, {})
    : {}
}
