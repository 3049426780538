import { api } from '../api'

export const taskGradeApi = api
  .enhanceEndpoints({
    addTagTypes: ['TaskGradeUsers', 'GradeItemTaskList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTaskGradeUsers: builder.query({
        providesTags: ['TaskGradeUsers'],
        query: (taskId) => {
          return {
            url: `/task-grade/${taskId}/users`,
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      addUserTaskGrade: builder.mutation({
        query: ({ taskId, params }) => ({
          url: `/task-grade/${taskId}/users`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: ['TaskGradeUsers'],
      }),
      removeUserTaskGrade: builder.mutation({
        query: ({ taskId, taskGradeId }) => ({
          url: `/task-grade/${taskId}/users/${taskGradeId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TaskGradeUsers'],
      }),
      getGradeItemTaskList: builder.query({
        providesTags: ['GradeItemTaskList'],
        query: (taskId) => {
          return {
            url: `/task/${taskId}/grade-item-task`,
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
      addGradeItemTask: builder.mutation({
        query: ({ taskId, params }) => ({
          url: `/task/${taskId}/grade-item-task`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: ['GradeItemTaskList'],
      }),
      removeGradeItemTask: builder.mutation({
        query: ({ taskId, taskGradeItemId }) => ({
          url: `/task/${taskId}/grade-item-task/${taskGradeItemId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GradeItemTaskList'],
      }),
      updateGradeItemTask: builder.mutation({
        query: ({ taskId, taskGradeItemId, params }) => ({
          url: `/task/${taskId}/grade-item-task/${taskGradeItemId}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: ['GradeItemTaskList'],
      }),
    }),
  })

export const {
  useAddUserTaskGradeMutation,
  useRemoveUserTaskGradeMutation,
  useGetTaskGradeUsersQuery,
  useGetGradeItemTaskListQuery,
  useAddGradeItemTaskMutation,
  useRemoveGradeItemTaskMutation,
  useUpdateGradeItemTaskMutation,
} = taskGradeApi
